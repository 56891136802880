import {observer} from 'mobx-react'
import {Dropdown} from 'react-bootstrap-v5'
import TableActionButton from '../TableActionButton'
import {getRandomKey} from '../../Helpers/Default'
import * as React from 'react'
import {ShowLess1024px} from '../Responsible'
import Tooltip from '../../../_metronic/helpers/components/Tooltip'
import {NavLink} from 'react-router-dom'

export const ActionButtonsArea = observer((props: any) => {
  const renderButtons = () => {
    const buttons = []
    for (let i in props.buttons) {
      const button = props.buttons[i]

      if (button.visible !== undefined && !button.visible) {
        continue
      }

      let button_html = (
        <TableActionButton
          data={props.data}
          key={getRandomKey() + '_' + button.loader ? 'd' : 'da'}
          action={button.action}
          color_type={`${button.color}`}
          className={button.className}
          tooltip={button.text}
          loader={button.loader}
          disabled={button.disabled}
        >
          <i className={button.icon}></i>
        </TableActionButton>
      )

      if (button.link) {
        button_html = (
          <NavLink to={button.link(props.data)} target={button.newTab ? '_blank' : ''}>
            {button_html}
          </NavLink>
        )
      }
      buttons.push(button_html)
    }

    return buttons
  }

  const renderMobileButtons = () => {
    const buttons = []
    for (let i in props.buttons) {
      const button = props.buttons[i]

      if (button.visible !== undefined && !button.visible) {
        continue
      }
      let htmlButton = (
        <div className={`text-${button.color} p-3`} onClick={button.action}>
          <Tooltip text={props.tooltip}>
            <i className={`${button.icon} me-2 text-${button.color}`}></i>{' '}
            <span className='ms-2'>{button.text}</span>
          </Tooltip>
        </div>
      )

      if (button.link) {
        htmlButton = (
          <NavLink to={button.link(props.data)} target={button.newTab ? '_blank' : ''}>
            {htmlButton}
          </NavLink>
        )
      }

      let dropDownButton = (
        <Dropdown.Item key={i} eventKey='4.1'>
          {htmlButton}
        </Dropdown.Item>
      )

      buttons.push(dropDownButton)
    }

    return (
      <Dropdown navbar={true}>
        <Dropdown.Toggle variant='link' bsPrefix='p-0'>
          <span>
            <i className='bi bi-three-dots-vertical'></i>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className={'z-index-98 py-0'}>{buttons}</Dropdown.Menu>
      </Dropdown>
    )
  }

  if (ShowLess1024px()) {
    return <>{renderMobileButtons()}</>
  } else {
    return <div className={'action-buttons-area text-right'}>{renderButtons()}</div>
  }
})
