import Tooltip from '../../../_metronic/helpers/components/Tooltip'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import UserStore from '../../Stores/User'
import {AUTHORIZED_PERSON_TYPES, COMMITTEE_ROLES, LIST_CRUD_ROLES} from '../../Constants/User'
import {TooltipDefault} from '../../Components/Tooltip'
import * as React from 'react'
import {FormattedMessage} from 'react-intl'
import {deleteLocalStorageValue} from '../LocalStorage'
import {USERS_MODULE} from '../../Constants/Modules'
import {CONTACT_TYPE_MAP} from '../../Constants/Contact'
import {hasModule} from '../Default'

export function renderFullNameWithIcon(
  user: any,
  isCircle: boolean = false,
  markStatus: boolean = false
) {
  return (
    <div className='d-flex align-items-center'>
      {getShortcutInCircle(user, isCircle)}
      <div className='d-flex justify-content-start flex-column m-0 ms-3'>
        <div className='text-dark fs-6 align-content-center d-inline-flex'>
          {user.isCompany ? <strong>{user.companyName}</strong> : getFullName(user)}

          {markStatus && hasCrudRole(USERS_MODULE) ? <div>{getUserStatusMark(user)}</div> : null}
        </div>
        {user.role && user.role.title ? (
          <div>
            <span className={'fs-8 text-muted fw-normal'}>{getRoleName(user)}</span>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export function getUserStatusMark(user: any) {
  let mark = null
  let markTooltip: any = ''
  if (user.isActive) {
    mark = (
      <KTSVG
        path='/media/icons/duotune/general/gen026.svg'
        className='svg-icon-3 ms-1 svg-icon-success'
      />
    )
    markTooltip = <FormattedMessage id={'USERS.TABLE.TOOLTIP.ACTIVE'} />
    // markTooltip = getTranslation('USERS.TABLE.TOOLTIP.ACTIVE', intl)
    // @ts-ignore
  } else if (user.hasAccess && !user.isActive) {
    mark = (
      <KTSVG
        path='/media/icons/duotune/general/gen013.svg'
        className='svg-icon-3 ms-1 svg-icon-warning'
      />
    )
    markTooltip = <FormattedMessage id={'USERS.TABLE.TOOLTIP.WAITING'} />
  } else {
    mark = (
      <KTSVG
        path='/media/icons/duotune/communication/com006.svg'
        className='svg-icon-3 ms-1 svg-icon-brand'
      />
    )
    markTooltip = <FormattedMessage id={'USERS.TABLE.TOOLTIP.NOT_ACCESS'} />
  }

  if (mark) {
    return (
      <TooltipDefault tooltip={markTooltip} placement={'top'}>
        <div>{mark}</div>
      </TooltipDefault>
    )
  }
  return ''
}

export function getRoleName(user: any) {
  if (!user || !user.role) {
    return ''
  }
  return user.role.title
}

export function getFullName(user: any) {
  if (!user) {
    return null
  }

  if (user.isCompany) {
    return user.companyName
  }

  if (!user.titleBefore && !user.titleAfter) {
    return (
      <span>
        {user.firstName} <strong> {user.lastName} </strong>{' '}
      </span>
    )
  }

  if (user.titleBefore && !user.titleAfter) {
    return (
      <span>
        {user.titleBefore} {user.firstName} <strong> {user.lastName} </strong>{' '}
      </span>
    )
  }

  if (!user.titleBefore && user.titleAfter) {
    return (
      <span>
        {user.firstName} <strong> {user.lastName} </strong>, {user.titleAfter}
      </span>
    )
  }

  if (user.titleBefore && user.titleAfter) {
    return (
      <span>
        {user.titleBefore} {user.firstName} <strong> {user.lastName}</strong>, {user.titleAfter}
      </span>
    )
  }
}

export function getShortcut(user: any) {
  if (!user || ((!user.firstName || !user.lastName) && !user.companyName)) {
    return null
  }

  if (user.isCompany && user.companyName) {
    return user.companyName[0].toUpperCase()
  }

  let shortcut = ''
  if (user.firstName[0]) {
    shortcut += user.firstName[0].toUpperCase()
  }

  if (user.lastName[0]) {
    shortcut += user.lastName[0].toUpperCase()
  }

  return shortcut || null
}

export function getFullNameSimple(user: any) {
  if (!user) {
    return null
  }

  if (user.isCompany) {
    return user.companyName
  }

  let fullName = ''

  if (user.titleBefore) {
    fullName += `${user.titleBefore} `
  }

  fullName += `${user.firstName} ${user.lastName}`

  if (user.titleAfter) {
    fullName += `, ${user.titleAfter}`
  }

  return fullName
}

export function getListOfOwners(owners: any) {
  if (owners && owners.length > 0) {
    const bo = owners.map((item: any) => renderFullNameWithIcon(item))
    if (bo) {
      return bo
    }
  }
  return ' - '
}

export function getShortcutInCircle(
  user: any,
  isCircle: boolean = false,
  customClass: string = ''
) {
  if (!user) {
    return null
  }

  let circleClass = ''
  let sexClass = 'bg-light-primary-custom sex-circle-male text-primary'
  if (isCircle) {
    circleClass = 'symbol-circle'
  }

  if (user.sex === 'female') {
    sexClass = 'bg-light-warning sex-circle-female'
  } else if (user.sex === 'others') {
    sexClass = 'bg-light-secondary sex-circle-others'
  }

  if (user.isCompany) {
    sexClass = 'bg-light-success sex-circle-company'
  }

  return (
    <div className={`symbol ${circleClass}`}>
      <Tooltip text={user.isCompany ? user.companyName : getFullNameSimple(user)}>
        <span
          className={`symbol-label ${sexClass} fw-bold-customer ${customClass}  ${
            customClass !== 'extra-large-size' ? 'symbol-label-45px' : ''
          }`}
        >
          {getShortcut(user)}
        </span>
        {/*<img src="media/avatars/300-14.jpg" alt="" />*/}
      </Tooltip>
    </div>
  )
}

export function getContactIcon(contact: any) {
  let sexClass = 'bg-light-success'

  let icon: any = ''
  if (contact.type === CONTACT_TYPE_MAP.company.value) {
    icon = <i className='far fa-building text-success'></i>
  } else {
    icon = <i className='far fa-user text-primary'></i>
    sexClass = 'bg-light-primary'
  }

  return (
    <div className={`symbol`}>
      <Tooltip text={contact.isCompany ? contact.companyName : getFullNameSimple(contact)}>
        <span className={`symbol-label ${sexClass} fw-bold-customer symbol-label-45px`}>
          {icon}
        </span>
        {/*<img src="media/avatars/300-14.jpg" alt="" />*/}
      </Tooltip>
    </div>
  )
}

export function getAddress(user: any) {
  return user.street + ', ' + user.city + ', ' + user.post
}

export function getUserIcon(user: any, customClass: string = '', markStatus: boolean = false) {
  if (!user) {
    return null
  }

  let symbolContent = getShortcutInCircle(user, false, customClass)

  if (user.avatar) {
    symbolContent = <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />
  }

  return (
    <div className='symbol symbol-35px'>
      {symbolContent}
      {markStatus ? (
        <span className='badge top right position-absolute' style={{right: 0, top: 0}}>
          {getUserStatusMark(user)}
        </span>
      ) : null}
    </div>
  )
}

export function hasCommitteeRole(user = UserStore.user) {
  if (!user?.role) {
    return true
  }
  return COMMITTEE_ROLES.includes(user.role.name) || LIST_CRUD_ROLES.includes(user.role.name)
}

export function hasCrudRole(module: string = '', user = UserStore.user) {
  if (!user?.role || module === '') {
    return true
  }
  return user.role.rights.indexOf('w') > -1 && user.role.modules.indexOf(module) > -1
}

export function userHasModule(module: string = '') {
  if (module === '') {
    return true
  }

  if (UserStore.user && !UserStore.user.role) {
    return true
  }

  return UserStore.user.role.modules.indexOf(module) > -1 && hasModule(module)
}

export function getRelationship(user: any) {
  if (!user) {
    return null
  }

  return (
    AUTHORIZED_PERSON_TYPES.find((item: any) => item.value === user.authorizedPersonType)?.label ??
    '-'
  )
}

export const logout = () => {
  deleteLocalStorageValue('svj')
  deleteLocalStorageValue('token')
  window.location.href = '/prihlasit'
}

export const removeAcademyDegree = (fullNameWithTitle: string) => {
  let textArray = fullNameWithTitle.split(' ')
  let fullname = []
  let isName = false
  let degreesBefore = []
  let degreesAfter = []
  for (let i in textArray) {
    const partName = textArray[i]
    if (partName.indexOf('.') > -1) {
      if (textArray.indexOf(',') <= textArray.indexOf(partName)) {
        degreesBefore.push(partName)
      } else {
        degreesAfter.push(partName)
      }
    } else {
      fullname.push(partName)
      isName = true
    }
  }

  return {
    fullname: fullname.join(' '),
    lastName: fullname[0],
    firstName: fullname[fullname.length - 1],
    titleBefore: degreesBefore,
    titleAfter: degreesAfter,
  }
}
