import { observer } from "mobx-react";
import * as React from "react";
import { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  CustomStepIcon,
  getDefaultSexOptions,
  getRandomKey,
  getTranslation,
} from "../../../../Helpers/Default";
import { COUNTRIES, getAllSvjRoles } from "../../../../Constants/Defatul";
import OrderStore from "../../../../Stores/Order";
import { checkFormErrors, checkValueError } from "../../../../Helpers/Form";
import { isEmpty } from "lodash";
import { useFormik } from "formik";
import { confirmDialog } from "../../../../Helpers/ConfirmDialog";
import InputColumn from "../../../../Components/InputColumn";
import SectionTitle from "../../../../Components/SectionTitle";
import SelectColumn from "../../../../Components/SelectColumn";
import CheckBoxColumn from "../../../../Components/CheckboxColumn";
import { InputLabel } from "../../../../Components/InputLabel";
import PhoneInput from "../../../../Components/PhoneInput";
import {
  getFullNameSimple,
  removeAcademyDegree,
} from "../../../../Helpers/User";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { DefaultButton } from "../../../../Components/Buttons/DefaultButton";
import ConfirmationDialog from "../../../../Components/ConfirmDialog";
import CardLayout from "../../../../Components/CardLayout";
import { PageTitle } from "../../../../../_metronic/layout/core";
import InputSimple from "../../../../Components/InputSimple";
import { MODULES } from "../../../../Constants/Modules";
import SvjStore from "../../../../Stores/Svj";
import { NumericFormat } from "react-number-format";
import { useHistory } from "react-router-dom";
const getInvoiceTypes = (intl: any) => {
  return [
    {
      value: "monthly",
      label: getTranslation("ADMIN.ORDER.FORM.INVOICE_TYPE.MONTHLY", intl),
    },
    {
      value: "annual",
      label: getTranslation("ADMIN.ORDER.FORM.INVOICE_TYPE.YEARLY", intl),
    },
  ];
};

const AdminOrderCheck: FC<{}> = observer((props: any) => {
  const intl = useIntl();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const [finishedForm, setFinishedForm] = React.useState(false);
  const [roles, setRoles] = React.useState<any[]>([]);
  const steps = [
    getTranslation("ADMIN.ORDER.FORM.CATEGORY.SVJ", intl),
    getTranslation("ADMIN.ORDER.FORM.CATEGORY.USER", intl),
    getTranslation("ADMIN.ORDER.FORM.CATEGORY.INVOICE", intl),
    getTranslation("ADMIN.ORDER.FORM.CATEGORY.MODULES", intl),
    getTranslation("ADMIN.ORDER.FORM.CATEGORY.KN", intl),
    getTranslation("ADMIN.ORDER.FORM.CATEGORY.RECAPITULATION", intl),
  ];
  const [entrances, setEntrances] = React.useState<any[]>([]);
  const [initialSvjValues, setInitialSvjValues] = React.useState({
    id: null,
    name: "",
    email: "",
    ic: "",
    dic: "",
    bankAccount: "",
    street: "",
    city: "",
    post: "",
    country: COUNTRIES.find((c) => c.value === "cs"),
    invoiceEmail: "",
    invoiceIc: "",
    invoiceDic: "",
    invoiceCompanyName: "",
    hasInvoiceAddress: false,
    invoiceStreet: "",
    invoiceCity: "",
    invoicePost: "",
    invoiceCountry: COUNTRIES.find((c) => c.value === "cs"),
    period: getInvoiceTypes(intl)[0],
    units: 0,
    price: 0,
    pricePerUnit: 0,
    terms_1: false,
    terms_2: false,
    modules: [],
    isFree: false,
    modules_string: "",
    cadastreVillage: "",
    cadastreStreet: "",
    cadastreNumber: "",
    cadastreMembers: [],
    cadastreMembersJSON: "",
  });

  const [initialUserValues, setInitialUserValues] = React.useState({
    userTitleBefore: "",
    userFirstName: "",
    userLastName: "",
    userTitleAfter: "",
    userSex: getDefaultSexOptions(intl)[0],
    userPhone: "+420",
    userEmail: "",
    userRole: getAllSvjRoles([])[0],
    userStreet: "",
    userCity: "",
    userPost: "",
    mainUserId: null,
    userCountry: COUNTRIES.find((c) => c.value === "cs"),
  });

  const [isFinished, setIsFinished] = React.useState(false);

  const [initialGlobalValues, setInitialGlobalValues] = React.useState({
    readOnly: false,
    key: getRandomKey(),
  });
  useEffect(() => {
    async function getData() {
      const id = props.match.params.id;
      const data = await OrderStore.getCheckForm(id);
      let mapRoles: any[] = [];
      console.log(data);
      if (data) {
        if (data.roles) {
          const filterDataRoles = data.roles.filter((role: any) =>
            [
              "mayorCommittee",
              "community_manager",
              "subMayorCommittee",
            ].includes(role.name)
          );
          mapRoles = filterDataRoles.map((role: any) => {
            return {
              value: role.id,
              label: role.title,
            };
          });
          setRoles(mapRoles);
        }
        const plan = JSON.parse(data.svj.svj.plan);

        setInitialSvjValues((prev: any) => {
          return {
            ...prev,
            units: plan.units,
            period: getInvoiceTypes(intl).find(
              (c: any) => c.value === plan.period
            ),
            price: plan.price,
            pricePerUnit: plan.pricePerUnit,
            ...data.svj.svj,
            id: data.svj.svj.id,
            isFree: data.svj.svj.isFree,
            modules: data.svj.modules,
            ic: data.svj.svj.ico,
            country: COUNTRIES.find((c) => c.value === data.svj.svj.country),
            invoiceCountry: COUNTRIES.find(
              (c) => c.value === data.svj.svj.invoiceCountry
            ),
            invoiceIc: data.svj.svj.invoiceIco,
            modules_string: data.svj.svj.modules,
            cadastreMembers: data.svj.svj.cadastreMembers
              ? mapMembers(JSON.parse(data.svj.svj.cadastreMembers).new)
              : [],
          };
        });

        setInitialUserValues((prev: any) => {
          return {
            ...prev,
            mainUserId: data.user.id,
            userTitleBefore: data.user.titleBefore,
            userFirstName: data.user.firstName,
            userLastName: data.user.lastName,
            userTitleAfter: data.user.titleAfter,
            userSex: getDefaultSexOptions(intl).find(
              (c: any) => c.value === data.user.sex
            ),
            userPhone: data.user.phone,
            userEmail: data.user.user.email,
            userRole: mapRoles.find((c: any) => c.value === data.user.role.id),
            userStreet: data.user.street,
            userCity: data.user.city,
            userPost: data.user.post,
            userCountry: COUNTRIES.find((c) => c.value === data.user.country),
          };
        });
      }
    }

    getData();
  }, []);

  const changeSvjValue = (e: any) => {
    setInitialSvjValues({
      ...initialSvjValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const formik = useFormik({
    initialValues: { ...initialSvjValues, ...initialUserValues },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      let errors = {};
      let isFocused = false;

      if (activeStep === 0) {
        isFocused = checkValueError(
          "name",
          isEmpty(initialSvjValues.name),
          isFocused,
          "ORDER.SVJ.FORM.VALIDATION.NAME",
          errors,
          intl
        );

        isFocused = checkValueError(
          "ic",
          isEmpty(initialSvjValues.ic),
          isFocused,
          "ORDER.SVJ.FORM.VALIDATION.IC",
          errors,
          intl
        );

        isFocused = checkValueError(
          "email",
          isEmpty(initialSvjValues.email),
          isFocused,
          "ORDER.SVJ.FORM.VALIDATION.EMAIL",
          errors,
          intl
        );

        isFocused = checkValueError(
          "street",
          isEmpty(initialSvjValues.street),
          isFocused,
          "ORDER.SVJ.FORM.VALIDATION.STREET",
          errors,
          intl
        );

        isFocused = checkValueError(
          "city",
          isEmpty(initialSvjValues.city),
          isFocused,
          "ORDER.SVJ.FORM.VALIDATION.CITY",
          errors,
          intl
        );

        isFocused = checkValueError(
          "post",
          isEmpty(initialSvjValues.post),
          isFocused,
          "ORDER.SVJ.FORM.VALIDATION.POST",
          errors,
          intl
        );

        isFocused = checkValueError(
          "country",
          isEmpty(initialSvjValues.country),
          isFocused,
          "ORDER.SVJ.FORM.VALIDATION.COUNTRY",
          errors,
          intl
        );

        if (initialSvjValues.hasInvoiceAddress) {
          isFocused = checkValueError(
            "invoiceCompanyName",
            isEmpty(initialSvjValues.invoiceCompanyName),
            isFocused,
            "ORDER.SVJ.FORM.VALIDATION.INVOICE_COMPANY_NAME",
            errors,
            intl
          );

          isFocused = checkValueError(
            "invoiceIc",
            isEmpty(initialSvjValues.invoiceIc),
            isFocused,
            "ORDER.SVJ.FORM.VALIDATION.INVOICE_IC",
            errors,
            intl
          );

          isFocused = checkValueError(
            "invoiceEmail",
            isEmpty(initialSvjValues.invoiceEmail),
            isFocused,
            "ORDER.SVJ.FORM.VALIDATION.INVOICE_EMAIL",
            errors,
            intl
          );

          isFocused = checkValueError(
            "invoiceStreet",
            isEmpty(initialSvjValues.invoiceStreet),
            isFocused,
            "ORDER.SVJ.FORM.VALIDATION.INVOICE_STREET",
            errors,
            intl
          );

          isFocused = checkValueError(
            "invoiceCity",
            isEmpty(initialSvjValues.invoiceCity),
            isFocused,
            "ORDER.SVJ.FORM.VALIDATION.INVOICE_CITY",
            errors,
            intl
          );

          isFocused = checkValueError(
            "invoicePost",
            isEmpty(initialSvjValues.invoicePost),
            isFocused,
            "ORDER.SVJ.FORM.VALIDATION.INVOICE_POST",
            errors,
            intl
          );

          isFocused = checkValueError(
            "invoiceCountry",
            isEmpty(initialSvjValues.invoiceCountry),
            isFocused,
            "ORDER.SVJ.FORM.VALIDATION.INVOICE_COUNTRY",
            errors,
            intl
          );
        }
      } else if (activeStep === 1) {
        isFocused = checkValueError(
          "userFirstName",
          isEmpty(initialUserValues.userFirstName),
          isFocused,
          "ORDER.SVJ.FORM.VALIDATION.USER.FIRSTNAME",
          errors,
          intl
        );

        isFocused = checkValueError(
          "userLastName",
          isEmpty(initialUserValues.userLastName),
          isFocused,
          "ORDER.SVJ.FORM.VALIDATION.USER.LASTNAME",
          errors,
          intl
        );

        isFocused = checkValueError(
          "userRole",
          isEmpty(initialUserValues.userRole),
          isFocused,
          "ORDER.SVJ.FORM.VALIDATION.USER.ROLE",
          errors,
          intl
        );

        isFocused = checkValueError(
          "userPhone",
          initialUserValues.userPhone.length < 9,
          isFocused,
          "ORDER.SVJ.FORM.VALIDATION.USER.PHONE",
          errors,
          intl
        );

        isFocused = checkValueError(
          "userEmail",
          isEmpty(initialUserValues.userEmail),
          isFocused,
          "ORDER.SVJ.FORM.VALIDATION.USER.EMAIL",
          errors,
          intl
        );

        isFocused = checkValueError(
          "userStreet",
          isEmpty(initialUserValues.userStreet),
          isFocused,
          "ORDER.SVJ.FORM.VALIDATION.USER.STREET",
          errors,
          intl
        );

        isFocused = checkValueError(
          "userCity",
          isEmpty(initialUserValues.userCity),
          isFocused,
          "ORDER.SVJ.FORM.VALIDATION.USER.CITY",
          errors,
          intl
        );

        isFocused = checkValueError(
          "userPost",
          isEmpty(initialUserValues.userPost),
          isFocused,
          "ORDER.SVJ.FORM.VALIDATION.USER.POST",
          errors,
          intl
        );

        isFocused = checkValueError(
          "userCountry",
          isEmpty(initialUserValues.userCountry),
          isFocused,
          "ORDER.SVJ.FORM.VALIDATION.USER.COUNTRY",
          errors,
          intl
        );
      } else if (activeStep === 2) {
        isFocused = checkValueError(
          "period",
          isEmpty(initialSvjValues.period),
          isFocused,
          "ADMIN.ORDER.FORM.INVOICE.PERIOD.VALIDATION",
          errors,
          intl
        );

        isFocused = checkValueError(
          "units",
          initialSvjValues.units < 1,
          isFocused,
          "ADMIN.ORDER.FORM.INVOICE.UNIT.VALIDATION",
          errors,
          intl
        );
      }

      if (!checkFormErrors(errors, setErrors, setSubmitting, formik)) {
        if (activeStep === steps.length - 1) {
          confirmDialog(
            "",
            getTranslation("ORDER.FORM.CONFIRM.SEND", intl),
            update
          );
        } else {
          handleNext();
        }
      }
    },
  });

  const update = async () => {
    const data = {
      ...initialSvjValues,
      invoiceCountry: initialSvjValues?.invoiceCountry?.value || "",
      country: initialSvjValues?.country?.value || "",
      entrances: entrances.map((entrance) => {
        return {
          name: entrance.name,
          street: entrance.street,
          city: entrance.city,
          post: entrance.post,
          country: entrance.country?.value || "",
        };
      }),
      user: {
        id: initialUserValues.mainUserId,
        firstName: initialUserValues.userFirstName,
        lastName: initialUserValues.userLastName,
        titleBefore: initialUserValues.userTitleBefore,
        titleAfter: initialUserValues.userTitleAfter,
        role: initialUserValues?.userRole?.value || "",
        phone: initialUserValues.userPhone,
        email: initialUserValues.userEmail,
        street: initialUserValues.userStreet,
        city: initialUserValues.userCity,
        post: initialUserValues.userPost,
        country: initialUserValues?.userCountry?.value || "",
      },
    };

    if (await OrderStore.acceptOrder(data, props.match.params.id)) {
      history.push("/objednavky");
      // setFinishedForm(true)
    }
  };

  const changeEntranceValue = (e: any, index: number) => {
    const newEntrances = [...entrances];
    newEntrances[index][e.target.name] = e.target.value;
    setEntrances(newEntrances);
  };

  const addNewEntrance = () => {
    setEntrances([
      ...entrances,
      {
        name: "",
        street: "",
        city: initialSvjValues.city,
        post: initialSvjValues.post,
        country: initialSvjValues.country,
      },
    ]);
  };

  const renderStep0 = () => {
    return (
      <div className="mt-5">
        <div className="row mb-1">
          <div className="col-12">
            <InputColumn
              value={initialSvjValues.name}
              name={"name"}
              changeValue={changeSvjValue}
              label={getTranslation("ORDER.SVJ.FORM.NAME", intl)}
              description={getTranslation(
                "ORDER.SVJ.FORM.NAME.DESCRIPTION",
                intl
              )}
              required={true}
              disabled={false}
              formik={formik}
            />
          </div>

          <div className="col-6">
            <InputColumn
              value={initialSvjValues.ic}
              name={"ic"}
              changeValue={changeSvjValue}
              label={getTranslation("ORDER.SVJ.FORM.IC", intl)}
              required={true}
              disabled={false}
              formik={formik}
            />
          </div>
          <div className="col-6">
            <InputColumn
              value={initialSvjValues.dic}
              name={"dic"}
              changeValue={changeSvjValue}
              label={getTranslation("ORDER.SVJ.FORM.DIC", intl)}
              description={getTranslation(
                "ORDER.SVJ.FORM.DIC.DESCRIPTION",
                intl
              )}
              required={false}
              disabled={false}
              formik={formik}
            />
          </div>
          <div className="col-6">
            <InputColumn
              value={initialSvjValues.email}
              name={"email"}
              changeValue={changeSvjValue}
              label={getTranslation("ORDER.SVJ.FORM.EMAIL", intl)}
              description={getTranslation(
                "ORDER.SVJ.FORM.EMAIL.DESCRIPTION",
                intl
              )}
              required={true}
              disabled={false}
              type={"email"}
              formik={formik}
            />
          </div>
          <div className="col-6">
            <InputColumn
              value={initialSvjValues.bankAccount}
              name={"bankAccount"}
              changeValue={changeSvjValue}
              label={getTranslation("ORDER.SVJ.FORM.BANK_ACCOUNT_NUMBER", intl)}
              required={false}
              disabled={false}
              formik={formik}
            />
          </div>
        </div>
        <div className="row mt-8">
          <div className="col-12">
            <SectionTitle
              buttons={[]}
              description={getTranslation(
                "ORDER.SVJ.TITLE.ADDRESS.DESCRIPTION",
                intl
              )}
            >
              {getTranslation("ORDER.SVJ.FORM.ADDRESS", intl)}
            </SectionTitle>
          </div>

          <div className="col-6">
            <InputColumn
              label={getTranslation("USER.FORM.STREET", intl)}
              name={"street"}
              type={"text"}
              value={initialSvjValues.street}
              placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
              required={true}
              formik={formik}
              changeValue={changeSvjValue}
              readOnly={initialGlobalValues.readOnly}
            />
          </div>
          <div className="col-6">
            <InputColumn
              label={getTranslation("USER.FORM.CITY", intl)}
              name={"city"}
              type={"text"}
              value={initialSvjValues.city}
              placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
              required={true}
              formik={formik}
              changeValue={changeSvjValue}
              readOnly={initialGlobalValues.readOnly}
            />
          </div>
          <div className="col-6">
            <InputColumn
              label={getTranslation("USER.FORM.POST", intl)}
              name={"post"}
              type={"text"}
              value={initialSvjValues.post}
              placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
              required={true}
              formik={formik}
              changeValue={changeSvjValue}
              readOnly={initialGlobalValues.readOnly}
            />
          </div>
          <div className="col-6">
            <SelectColumn
              values={initialSvjValues}
              label={getTranslation("USER.FORM.COUNTRY", intl)}
              name={"country"}
              placeholder={getTranslation("ROOMS.FORM.PLACEHOLDER", intl)}
              formik={formik}
              availableSearch={true}
              required={true}
              changeValue={changeSvjValue}
              data={COUNTRIES}
              readOnly={initialGlobalValues.readOnly}
              option={{
                value: "value",
                name: function (value: any) {
                  return value["label"];
                },
              }}
            />
          </div>
        </div>

        <div className={"row align-items-center mb-2"}>
          <div className="col-12 d-inline-flex align-content-center align-items-center">
            <CheckBoxColumn
              name={"hasInvoiceAddress"}
              changeValue={changeSvjValue}
              value={initialSvjValues.hasInvoiceAddress}
              readOnly={initialGlobalValues.readOnly}
              columnLabel={false}
            />
            <InputLabel
              onClick={() => {}}
              colSize={"col-4"}
              className={`mb-0 ps-2`}
              readOnly={initialGlobalValues.readOnly}
              inlineLabel={true}
            >
              {getTranslation("ORDER.SVJ.FORM.HAS_INVOICE_ADDRESS", intl)}
            </InputLabel>
          </div>
        </div>
        {initialSvjValues.hasInvoiceAddress ? (
          <div className="row mt-8">
            <div className="col-12">
              <SectionTitle
                buttons={[]}
                description={getTranslation(
                  "ORDER.SVJ.TITLE.INVOICE_ADDRESS.DESCRIPTION",
                  intl
                )}
              >
                {getTranslation("ORDER.SVJ.TITLE.INVOICE_ADDRESS", intl)}
              </SectionTitle>
            </div>

            <div className="col-6">
              <InputColumn
                label={getTranslation(
                  "ORDER.SVJ.TITLE.INVOICE_ADDRESS.NAME",
                  intl
                )}
                name={"invoiceCompanyName"}
                type={"text"}
                value={initialSvjValues.invoiceCompanyName}
                placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
                required={true}
                formik={formik}
                changeValue={changeSvjValue}
                readOnly={initialGlobalValues.readOnly}
              />
            </div>

            <div className="col-6">
              <InputColumn
                label={getTranslation(
                  "ORDER.SVJ.TITLE.INVOICE_ADDRESS.EMAIL",
                  intl
                )}
                name={"invoiceEmail"}
                type={"email"}
                value={initialSvjValues.invoiceEmail}
                placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
                required={true}
                description={getTranslation(
                  "ORDER.SVJ.TITLE.INVOICE_ADDRESS.EMAIL.DESCRIPTION",
                  intl
                )}
                formik={formik}
                changeValue={changeSvjValue}
                readOnly={initialGlobalValues.readOnly}
              />
            </div>

            <div className="col-6">
              <InputColumn
                value={initialSvjValues.invoiceIc}
                name={"invoiceIc"}
                changeValue={changeSvjValue}
                label={getTranslation("ORDER.SVJ.FORM.IC", intl)}
                required={true}
                disabled={false}
                formik={formik}
              />
            </div>
            <div className="col-6">
              <InputColumn
                value={initialSvjValues.invoiceDic}
                name={"invoiceDic"}
                changeValue={changeSvjValue}
                label={getTranslation("ORDER.SVJ.FORM.DIC", intl)}
                description={getTranslation(
                  "ORDER.SVJ.FORM.DIC.DESCRIPTION",
                  intl
                )}
                required={false}
                disabled={false}
                formik={formik}
              />
            </div>

            <div className="col-6">
              <InputColumn
                label={getTranslation("USER.FORM.STREET", intl)}
                name={"invoiceStreet"}
                type={"text"}
                value={initialSvjValues.invoiceStreet}
                placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
                required={true}
                formik={formik}
                changeValue={changeSvjValue}
                readOnly={initialGlobalValues.readOnly}
              />
            </div>
            <div className="col-6">
              <InputColumn
                label={getTranslation("USER.FORM.CITY", intl)}
                name={"invoiceCity"}
                type={"text"}
                value={initialSvjValues.invoiceCity}
                placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
                required={true}
                formik={formik}
                changeValue={changeSvjValue}
                readOnly={initialGlobalValues.readOnly}
              />
            </div>
            <div className="col-6">
              <InputColumn
                label={getTranslation("USER.FORM.POST", intl)}
                name={"invoicePost"}
                type={"text"}
                value={initialSvjValues.invoicePost}
                placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
                required={true}
                formik={formik}
                changeValue={changeSvjValue}
                readOnly={initialGlobalValues.readOnly}
              />
            </div>
            <div className="col-6">
              <SelectColumn
                values={initialSvjValues}
                label={getTranslation("USER.FORM.COUNTRY", intl)}
                name={"invoiceCountry"}
                placeholder={getTranslation("ROOMS.FORM.PLACEHOLDER", intl)}
                formik={formik}
                availableSearch={true}
                required={true}
                changeValue={changeSvjValue}
                data={COUNTRIES}
                readOnly={initialGlobalValues.readOnly}
                option={{
                  value: "value",
                  name: function (value: any) {
                    return value["label"];
                  },
                }}
              />
            </div>
          </div>
        ) : null}
        <div className="row mt-8">
          <div className="col-12">
            <SectionTitle
              buttons={[
                <DefaultButton
                  className={`btn btn-primary btn-sm`}
                  onClick={addNewEntrance}
                >
                  {getTranslation("ADMIN.ORDER.ENTRANCE.BUTTON.ADD", intl)}
                </DefaultButton>,
              ]}
            >
              {getTranslation("ADMIN.ORDER.ENTRANCE.TITLE", intl)}
            </SectionTitle>
          </div>
          <div className="col-12">{getEntranceTable(true)}</div>
        </div>
      </div>
    );
  };

  const getEntranceTable = (isEdit: boolean) => {
    if (!isEdit) {
      return (
        <div>
          {(entrances || []).map((entrance, index) => {
            return (
              <div className="row">
                <div className="col-4 fw-bold-custom text-muted">
                  {entrance.name}
                </div>
                <div className="col-8 fw-semibold text-gray-800 fs-6">
                  {entrance.street}, {entrance.city}, {entrance.post},{" "}
                  {entrance.country.label}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return (
      <table className="table align-middle" key={entrances.length + "_table"}>
        <thead>
          <tr>
            <th>
              {getTranslation("ADMIN.ORDER.ENTRANCE.TABLE.COLUMN.NAME", intl)}
            </th>
            <th>
              {getTranslation("ADMIN.ORDER.ENTRANCE.TABLE.COLUMN.STREET", intl)}
            </th>
            {isEdit ? (
              <th>
                {getTranslation(
                  "ADMIN.ORDER.ENTRANCE.TABLE.COLUMN.ACTION",
                  intl
                )}
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {(entrances || []).map((entrance, index) => {
            return (
              <tr>
                <td>
                  <InputSimple
                    value={entrance.name}
                    name={"name"}
                    readOnly={!isEdit}
                    changeValue={(e: any) => changeEntranceValue(e, index)}
                  />
                </td>
                <td>
                  <InputSimple
                    value={entrance.street}
                    name={"street"}
                    readOnly={!isEdit}
                    changeValue={(e: any) => changeEntranceValue(e, index)}
                  />
                </td>
                {isEdit ? (
                  <td>
                    <DefaultButton
                      className={"btn btn-light-danger btn-sm text-center"}
                      onClick={() => {
                        setEntrances(entrances.filter((e, i) => i !== index));
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </DefaultButton>
                  </td>
                ) : null}
              </tr>
            );
          })}
          {entrances.length === 0 ? (
            <tr>
              <td colSpan={3} className={"text-center"}>
                <span>
                  {getTranslation("ADMIN.ORDER.ENTRANCE.TABLE.EMPTY", intl)}
                </span>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    );
  };

  const renderStep1 = () => {
    return (
      <div className="mt-5">
        <div className={"row"}>
          <div className="col-2">
            <InputColumn
              name={"userTitleBefore"}
              value={initialUserValues.userTitleBefore}
              label={getTranslation("USER.FORM.TITLE_BEFORE", intl)}
              placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
              required={false}
              formik={formik}
              changeValue={changeUserValue}
              readOnly={initialGlobalValues.readOnly}
            />
          </div>
          <div className="col-4">
            <InputColumn
              label={getTranslation("USER.FORM.FIRSTNAME", intl)}
              name={"userFirstName"}
              type={"text"}
              value={initialUserValues.userFirstName}
              placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
              required={true}
              formik={formik}
              changeValue={changeUserValue}
              readOnly={initialGlobalValues.readOnly}
            />
          </div>
          <div className="col-4">
            <InputColumn
              name={"userLastName"}
              value={initialUserValues.userLastName}
              label={getTranslation("USER.FORM.LASTNAME", intl)}
              placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
              required={true}
              formik={formik}
              changeValue={changeUserValue}
              readOnly={initialGlobalValues.readOnly}
              inputBold={true}
            />
          </div>
          <div className="col-2">
            <InputColumn
              name={"userTitleAfter"}
              label={getTranslation("USER.FORM.TITLE_AFTER", intl)}
              value={initialUserValues.userTitleAfter}
              placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
              required={false}
              formik={formik}
              changeValue={changeUserValue}
              readOnly={initialGlobalValues.readOnly}
            />
          </div>
          <div className="col-6">
            <SelectColumn
              values={initialUserValues}
              label={getTranslation("USER.FORM.FUNCTION", intl)}
              name={"userRole"}
              placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
              formik={formik}
              required={true}
              changeValue={changeUserValue}
              data={roles}
              readOnly={initialGlobalValues.readOnly}
              option={{
                value: "value",
                name: function (value: any) {
                  if (value.translate) {
                    return getTranslation(value.title, intl);
                  }

                  return value.label;
                },
              }}
            />
          </div>
          <div className="col-6">
            <SelectColumn
              values={initialUserValues}
              name={"userSex"}
              label={getTranslation("USER.FORM.SEX", intl)}
              placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
              formik={formik}
              required={true}
              changeValue={changeUserValue}
              data={getDefaultSexOptions(intl)}
              readOnly={initialGlobalValues.readOnly}
              option={{
                value: "value",
                name: function (value: any) {
                  return value["label"];
                },
              }}
            />
          </div>
          <div className="col-6">
            <PhoneInput
              name={"userPhone"}
              label={getTranslation("USER.FORM.PHONE", intl)}
              value={initialUserValues.userPhone}
              placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
              required={true}
              formik={formik}
              changeValue={changeUserValue}
              className={"w-100"}
              readOnly={initialGlobalValues.readOnly}
              inputBold={true}
            />
          </div>
          <div className="col-6">
            <InputColumn
              value={initialUserValues.userEmail}
              name={"userEmail"}
              changeValue={changeUserValue}
              label={getTranslation("ORDER.SVJ.FORM.EMAIL", intl)}
              description={getTranslation(
                "ORDER.SVJ.FORM.EMAIL.DESCRIPTION",
                intl
              )}
              required={true}
              disabled={false}
              type={"email"}
              formik={formik}
            />
          </div>
        </div>
        <div className="col-12">
          <SectionTitle buttons={[]}>
            {getTranslation("USER.FORM.ADDRESS", intl)}
          </SectionTitle>
        </div>
        <div className="row">
          <div className="col-6">
            <InputColumn
              label={getTranslation("USER.FORM.STREET", intl)}
              name={"userStreet"}
              type={"text"}
              value={initialUserValues.userStreet}
              placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
              required={true}
              formik={formik}
              changeValue={changeUserValue}
              readOnly={initialGlobalValues.readOnly}
            />
          </div>
          <div className="col-6">
            <InputColumn
              label={getTranslation("USER.FORM.CITY", intl)}
              name={"userCity"}
              type={"text"}
              value={initialUserValues.userCity}
              placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
              required={true}
              formik={formik}
              changeValue={changeUserValue}
              readOnly={initialGlobalValues.readOnly}
            />
          </div>
        </div>
        <div className={"row"}>
          <div className="col-6">
            <InputColumn
              label={getTranslation("USER.FORM.POST", intl)}
              name={"userPost"}
              type={"text"}
              value={initialUserValues.userPost}
              placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
              required={true}
              formik={formik}
              changeValue={changeUserValue}
              readOnly={initialGlobalValues.readOnly}
            />
          </div>
          <div className="col-6">
            <SelectColumn
              values={initialUserValues}
              label={getTranslation("USER.FORM.COUNTRY", intl)}
              name={"userCountry"}
              placeholder={getTranslation("ROOMS.FORM.PLACEHOLDER", intl)}
              formik={formik}
              availableSearch={true}
              required={true}
              changeValue={changeUserValue}
              data={COUNTRIES}
              readOnly={initialGlobalValues.readOnly}
              option={{
                value: "value",
                name: function (value: any) {
                  return value["label"];
                },
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderStep2 = () => {
    return (
      <div className="mt-5">
        <div className="row">
          <div className="col-6">
            <SelectColumn
              values={initialSvjValues}
              label={getTranslation(
                "ADMIN.ORDER.FORM.INVOICE.PERIOD.TITLE",
                intl
              )}
              name={"period"}
              placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
              formik={formik}
              required={true}
              changeValue={changeSvjValue}
              data={getInvoiceTypes(intl)}
              description={getTranslation(
                "ADMIN.ORDER.FORM.INVOICE.PERIOD.TITLE.DESCRIPTION",
                intl
              )}
              readOnly={initialGlobalValues.readOnly}
              option={{
                value: "value",
                name: function (value: any) {
                  if (value.translate) {
                    return getTranslation(value.title, intl);
                  }

                  return value.label;
                },
              }}
            />
          </div>
          <div className="col-6">
            <InputColumn
              name={"units"}
              label={getTranslation(
                "ADMIN.ORDER.FORM.INVOICE.UNIT.TITLE",
                intl
              )}
              value={initialSvjValues.units}
              placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
              required={true}
              formik={formik}
              changeValue={changeSvjValue}
              readOnly={initialGlobalValues.readOnly}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <InputColumn
              name={"pricePerUnit"}
              label={getTranslation(
                "ADMIN.ORDER.FORM.INVOICE.PRICE_PER_UNIT.TITLE",
                intl
              )}
              value={initialSvjValues.pricePerUnit}
              placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
              required={true}
              formik={formik}
              changeValue={changeSvjValue}
              readOnly={initialGlobalValues.readOnly}
            />
          </div>
          <div className="col-6">
            <InputColumn
              name={"price"}
              label={getTranslation(
                "ADMIN.ORDER.FORM.INVOICE.PRICE.TITLE",
                intl
              )}
              value={initialSvjValues.price}
              placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
              required={true}
              formik={formik}
              changeValue={changeSvjValue}
              readOnly={initialGlobalValues.readOnly}
            />
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12">
            {getTranslation("ADMIN.ORDER.FORM.INVOICE.DESCRIPTION", intl)}{" "}
            <a
              href="https://www.svjaplikace.cz/cenik"
              target={"_blank"}
              rel="noreferrer"
            >
              www.svjaplikace.cz/cenik
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderStep3 = () => {
    return (
      <div className="mt-5">
        <div className="row mb-8">
          <div className="col-12">
            <SectionTitle buttons={[]} titleWidth={"w-100"}>
              {getTranslation(
                "ADMIN.ORDER.FORM.INVOICE.RECAPITULATION.ORDER.TITLE",
                intl
              )}
            </SectionTitle>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation(
                  "ADMIN.ORDER.FORM.INVOICE.RECAPITULATION.ORDER.NAME",
                  intl
                )}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">
                {initialSvjValues.name}
              </div>
            </div>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation(
                  "ADMIN.ORDER.FORM.INVOICE.RECAPITULATION.ORDER.ADDRESS",
                  intl
                )}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">
                {initialSvjValues.street}, {initialSvjValues.city},{" "}
                {initialSvjValues.post}, {initialSvjValues.country?.label || ""}
              </div>
            </div>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation(
                  "ADMIN.ORDER.FORM.INVOICE.RECAPITULATION.ORDER.IC",
                  intl
                )}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">
                {initialSvjValues.ic}
              </div>
            </div>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation(
                  "ADMIN.ORDER.FORM.INVOICE.RECAPITULATION.ORDER.DIC",
                  intl
                )}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">
                {initialSvjValues.dic}
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-8">
          <div className="col-12">
            <SectionTitle
              buttons={[]}
              titleWidth={"w-100"}
              description={getTranslation(
                "ADMIN.ORDER.FORM.INVOICE.RECAPITULATION.CONTACT.DESCRIPTION",
                intl
              )}
            >
              {getTranslation(
                "ADMIN.ORDER.FORM.INVOICE.RECAPITULATION.CONTACT.TITLE",
                intl
              )}
            </SectionTitle>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation(
                  "ADMIN.ORDER.FORM.INVOICE.RECAPITULATION.CONTACT.NAME",
                  intl
                )}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">
                {getFullNameSimple({
                  titleBefore: initialUserValues.userTitleBefore,
                  firstName: initialUserValues.userFirstName,
                  lastName: initialUserValues.userLastName,
                  titleAfter: initialUserValues.userTitleAfter,
                })}
              </div>
            </div>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation(
                  "ADMIN.ORDER.FORM.INVOICE.RECAPITULATION.CONTACT.EMAIL",
                  intl
                )}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">
                {initialUserValues.userEmail}
              </div>
            </div>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation(
                  "ADMIN.ORDER.FORM.INVOICE.RECAPITULATION.CONTACT.PHONE",
                  intl
                )}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">
                {initialUserValues.userPhone}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-8">
          <div className="col-12">
            <SectionTitle buttons={[]} titleWidth={"w-100"}>
              {getTranslation("ADMIN.ORDER.ENTRANCE.TITLE", intl)}
            </SectionTitle>
            <div className="row">{getEntranceTable(false)}</div>
          </div>
        </div>
        {renderStep4(false)}
        <div className="row mt-4 mb-8">
          <div className="col-12">
            <SectionTitle buttons={[]} titleWidth={"w-100"}>
              {getTranslation(
                "ADMIN.ORDER.FORM.INVOICE.RECAPITULATION.PAYMENT.TITLE",
                intl
              )}
            </SectionTitle>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation(
                  "ADMIN.ORDER.FORM.INVOICE.RECAPITULATION.PAYMENT.PERIOD",
                  intl
                )}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">
                {initialSvjValues.period?.label || ""}
              </div>
            </div>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation(
                  "ADMIN.ORDER.FORM.INVOICE.RECAPITULATION.PAYMENT.LICENCE_COUNT",
                  intl
                )}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">1</div>
            </div>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation(
                  "ADMIN.ORDER.FORM.INVOICE.RECAPITULATION.PAYMENT.UNITS",
                  intl
                )}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">
                {initialSvjValues.units}
              </div>
            </div>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation(
                  "ADMIN.ORDER.FORM.INVOICE.PRICE_PER_UNIT.TITLE",
                  intl
                )}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">
                <NumericFormat
                  value={initialSvjValues.pricePerUnit}
                  name={"value"}
                  displayType={"text"}
                  allowLeadingZeros
                  decimalSeparator={"."}
                  thousandSeparator=" "
                  defaultValue={initialSvjValues.pricePerUnit}
                />{" "}
                {getTranslation("BASE.CURRENCY.CS.INPUT", intl)}
              </div>
            </div>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation("ADMIN.ORDER.FORM.INVOICE.PRICE.TITLE", intl)}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">
                <NumericFormat
                  value={initialSvjValues.price}
                  name={"value"}
                  displayType={"text"}
                  allowLeadingZeros
                  decimalSeparator={"."}
                  thousandSeparator=" "
                  defaultValue={initialSvjValues.price}
                />{" "}
                {getTranslation("BASE.CURRENCY.CS.INPUT", intl)}
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-8">
          <div className="col-12">
            <SectionTitle buttons={[]} titleWidth={"w-100"}>
              {getTranslation(
                "ADMIN.ORDER.FORM.INVOICE.RECAPITULATION.UNITS.TITLE",
                intl
              )}
            </SectionTitle>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation("COMPANY.TITLE", intl)}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">
                {getTranslation("COMPANY.VALUE", intl)}
              </div>
            </div>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation("COMPANY.IC.TITLE", intl)}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">
                {getTranslation("COMPANY.IC.VALUE", intl)}
              </div>
            </div>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation("COMPANY.DIC.TITLE", intl)}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">
                {getTranslation("COMPANY.DIC.VALUE", intl)}
              </div>
            </div>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation("COMPANY.ADDRESS.TITLE", intl)}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">
                {getTranslation("COMPANY.ADDRESS.VALUE", intl)}
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 fw-semibold text-gray-800 fs-6">
                {getTranslation("COMPANY.DESCRIPTION", intl)}
              </div>
            </div>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation("COMPANY.EMAIL.TITLE", intl)}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">
                {getTranslation("COMPANY.EMAIL.VALUE", intl)}
              </div>
            </div>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation("COMPANY.BANK_ACCOUNT.TITLE", intl)}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">
                {getTranslation("COMPANY.BANK_ACCOUNT.VALUE", intl)}
              </div>
            </div>
            <div className="row">
              <div className="col-4 fw-bold-custom text-muted">
                {getTranslation("COMPANY.WEB.TITLE", intl)}
              </div>
              <div className="col-8 fw-semibold text-gray-800 fs-6">
                {getTranslation("COMPANY.WEB.VALUE", intl)}
              </div>
            </div>
            <div className="separator mt-5 mb-5"></div>
            <div className="row mb-5">
              <div className="col-12 fw-semibold text-gray-800 fs-6">
                {getTranslation("COMPANY.ORDER.DESCRIPTION", intl)}
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 fw-semibold text-gray-800 fs-6">
                {getTranslation("COMPANY.ORDER.DESCRIPTION_2", intl)}
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 fw-semibold text-gray-800 fs-6">
                {getTranslation("COMPANY.ORDER.DESCRIPTION_3", intl)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const changeModule = (e: any) => {
    const value = e.target.name;
    let modules = initialSvjValues.modules_string.split(",");
    if (modules.indexOf(value) > -1) {
      modules = modules.filter(
        (m: any) => m.toLowerCase() !== value.toLowerCase()
      );
    } else {
      modules.push(value);
    }

    setInitialSvjValues({
      ...initialSvjValues,
      modules_string: modules.join(","),
    });
  };

  const changeMainUser = (e: any, index: any) => {
    const old = initialSvjValues.cadastreMembers;
    const foundIndex = old.findIndex((x: any) => x.mainUser === true);
    if (foundIndex > -1 && foundIndex !== index) {
      // @ts-ignore
      old[foundIndex] = {
        // @ts-ignore
        ...old[foundIndex],
        // @ts-ignore
        mainUser: false,
      };
    }
    let isMainUser = true;
    // @ts-ignore
    if (
      old[index] &&
      // @ts-ignore
      old[index]?.mainUser !== undefined &&
      // @ts-ignore
      old[index]?.mainUser === true
    ) {
      isMainUser = false;
    }
    // @ts-ignore
    old[index] = {
      // @ts-ignore
      ...old[index],
      // @ts-ignore
      mainUser: isMainUser,
    };
    setInitialSvjValues({
      ...initialSvjValues,
      cadastreMembers: old,
    });
  };

  const mapMembers = (members: any) => {
    return members.map((member: any, index: number) => {
      const nameInfo = removeAcademyDegree(member.name);
      let entrance = null;
      const sex = nameInfo.lastName.indexOf("ová") > -1 ? "female" : "male";
      return {
        firstName: nameInfo.firstName,
        lastName: nameInfo.lastName,
        titleBefore: nameInfo.titleBefore.join(" "),
        titleAfter: nameInfo.titleAfter.join(" "),
        sex: getDefaultSexOptions(intl).find((c: any) => c.value === sex),
        entrance: null,
        fullAddress: member.address,
        share: member.share,
        unit: member.unit,
        catastr_org_data: member,
        description: member.type,
      };
    });
  };

  const changeOwnersValue = (e: any, index: any) => {
    const { name, value } = e.target;
    const old = initialSvjValues.cadastreMembers;
    // @ts-ignore
    old[index] = {
      // @ts-ignore
      ...old[index],
      [name]: value,
    };
    setInitialSvjValues({
      ...initialSvjValues,
      cadastreMembers: old,
    });
  };

  const renderStep4 = (isEdit = true) => {
    return (
      <div className="row">
        <div className="col-12">
          <SectionTitle buttons={[]} titleWidth={"w-100"}>
            {getTranslation("ADMIN.ORDER.FORM.INVOICE.MODULES.TITLE", intl)}
          </SectionTitle>
        </div>
        <div className="col-12">
          <div className={"row"}>
            {MODULES.map((module: any, index: number) => {
              return (
                <div className={"col-4"}>
                  <div className="row">
                    <div className="col-12 align-items-center d-inline-flex">
                      <CheckBoxColumn
                        name={module.id}
                        changeValue={changeModule}
                        value={
                          initialSvjValues.modules_string
                            .toLowerCase()
                            .indexOf(module.id.toLowerCase()) > -1
                        }
                        readOnly={!isEdit}
                        columnLabel={false}
                      />

                      <InputLabel
                        onClick={() => {}}
                        colSize={"col-8"}
                        className={`mb-0 ps-2`}
                        readOnly={initialGlobalValues.readOnly}
                        inlineLabel={true}
                      >
                        {getTranslation(module.name, intl)}
                      </InputLabel>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const getAddress = (data: any) => {
    const dataChange = data.split(",");
    return dataChange.slice(1, dataChange.length).join(",");
  };

  const renderStep5 = () => {
    return (
      <div className="row">
        <div className="col-12">
          <SectionTitle buttons={[]} titleWidth={"w-100"}>
            {getTranslation("ADMIN.ORDER.FORM.CADASTER.TITLE", intl)}
          </SectionTitle>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <InputColumn
                name={"cadastreVillage"}
                label={getTranslation(
                  "ADMIN.ORDER.FORM.CADASTER.VILLAGE.TITLE",
                  intl
                )}
                value={initialSvjValues.cadastreVillage}
                placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
                required={true}
                formik={formik}
                changeValue={changeSvjValue}
                readOnly={initialGlobalValues.readOnly}
              />
            </div>
            <div className="col-6">
              <InputColumn
                name={"cadastreStreet"}
                label={getTranslation(
                  "ADMIN.ORDER.FORM.CADASTER.STREET.TITLE",
                  intl
                )}
                value={initialSvjValues.cadastreStreet}
                placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
                required={true}
                formik={formik}
                changeValue={changeSvjValue}
                readOnly={initialGlobalValues.readOnly}
              />
            </div>
            <div className="col-6">
              <InputColumn
                name={"cadastreNumber"}
                label={getTranslation(
                  "ADMIN.ORDER.FORM.CADASTER.NUMBER.TITLE",
                  intl
                )}
                value={initialSvjValues.cadastreNumber}
                placeholder={getTranslation("USER.FORM.PLACEHOLDER", intl)}
                required={true}
                formik={formik}
                changeValue={changeSvjValue}
                readOnly={initialGlobalValues.readOnly}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <DefaultButton
                className={`btn btn-success btn-sm`}
                type={"button"}
                disabled={true}
                onClick={async () => {
                  const response = await SvjStore.getCadaster(
                    initialSvjValues.id,
                    initialSvjValues.cadastreNumber,
                    initialSvjValues.cadastreVillage,
                    initialSvjValues.cadastreStreet
                  );
                  const cadastreMembers = response.data.people
                    ? JSON.parse(response.data.people)
                    : { before: [], new: [] };

                  setInitialSvjValues({
                    ...initialSvjValues,
                    cadastreMembers: mapMembers(cadastreMembers.new),
                  });
                }}
              >
                {getTranslation(
                  "ADMIN.ORDER.FORM.CADASTER.IMPORT_BUTTON",
                  intl
                )}
              </DefaultButton>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <textarea
                className="form-control"
                onChange={(e: any) => {
                  const people: any = [];
                  const page = e.target.value;
                  if (page) {
                    // Vytvoření dočasného elementu pro parsování HTML
                    const parser = new DOMParser();
                    const doc: any = parser.parseFromString(page, "text/html");

                    // Extrahování dat

                    // Selektory pro vlastníky a jiná oprávnění
                    const vlastniciElement = doc.querySelector(
                      'table[summary="Vlastníci, jiní oprávnění"]'
                    );

                    // Vytvoření výsledného textu
                    const trs = vlastniciElement.querySelectorAll("tr");
                    let skyp = 0;
                    let nextShare = "";
                    let nextUnit = "";
                    for (let i in trs) {
                      const tr = trs[i];

                      if (typeof tr === "object" && tr !== "sjm") {
                        const tds = tr.getElementsByTagName("td");
                        if (tds && tds[0] !== undefined) {
                          let name = "";
                          let address = "";
                          let share = "";
                          let unit = "";

                          if (tds[1] !== undefined) {
                            share = tds[1].childNodes[0].textContent;
                          }

                          // Rozšířená logika pro získání informací o jednotce
                          if (tds[0] !== undefined) {
                            const tdContent = tds[0].innerHTML;
                            const unitMatch = tdContent.match(
                              /Jednotka:.*?(\d+\/\d+)/
                            );
                            if (unitMatch && unitMatch[1]) {
                              unit = unitMatch[1]; // Extrahujeme hodnotu jednotky
                            }
                          }

                          if (
                            tds[0] !== undefined &&
                            tds[0].childNodes[0].textContent.indexOf("SJM") >
                              -1 &&
                            tds[0].childNodes[0].textContent.split(",")
                              .length <= 2 &&
                            tds[0].getElementsByTagName("i").length === 0
                          ) {
                            nextShare = share;
                            nextUnit = unit;
                          } else if (
                            tds[0] !== undefined &&
                            tds[0].childNodes[0].textContent.indexOf("SJM") >
                              -1 &&
                            skyp === 0 &&
                            tds[0].getElementsByTagName("i").length === 0
                          ) {
                            const fullInfo =
                              tds[0].childNodes[0].textContent.split(",");

                            let names = fullInfo[0].replace("SJM", "");
                            names = names.split(" a ");
                            address = getAddress(
                              tds[0].childNodes[0].textContent
                            );

                            for (let j in names) {
                              people.push({
                                name: names[j].trim(),
                                address: address.trim(),
                                share: share,
                                type: "SJM",
                                unit: unit.trim(),
                              });
                            }
                          } else if (
                            tds[0].getElementsByTagName("i").length > 0
                          ) {
                            let fullInfo = tds[0].getElementsByTagName("i")[0];

                            let fullInfo2 =
                              fullInfo.childNodes[0].textContent.split(",");
                            address = getAddress(
                              fullInfo.childNodes[0].textContent
                            );
                            name = fullInfo2[0];

                            people.push({
                              name: name.trim(),
                              address: address.trim(),
                              share: nextShare,
                              type: "SJM",
                              unit: nextUnit.trim(),
                            });
                          } else {
                            if (tds[0] !== undefined && tds[1] !== undefined) {
                              const fullInfo =
                                tds[0].childNodes[0].textContent.split(",");
                              address = getAddress(
                                tds[0].childNodes[0].textContent
                              );
                              name = fullInfo[0];
                            }

                            people.push({
                              name: name.trim(),
                              address: address.trim(),
                              share: share.trim(),
                              unit: unit.trim(),
                            });
                          }

                          if (skyp > 0) {
                            skyp--;
                          }
                        }
                      }
                    }
                    const cadastreMembers =
                      people.length > 0
                        ? { before: [], new: people }
                        : { before: [], new: [] };

                    setInitialSvjValues({
                      ...initialSvjValues,
                      cadastreMembers: mapMembers(cadastreMembers.new),
                      cadastreMembersJSON: JSON.stringify(cadastreMembers),
                    });
                  }
                }}
                style={{ height: "300px" }}
              />
            </div>
          </div>
          <div className="row mt-8">
            <div className="col-12">
              <SectionTitle buttons={[]} titleWidth={"w-100"}>
                {getTranslation(
                  "ADMIN.ORDER.FORM.CADASTER.MEMBERS.TITLE",
                  intl
                )}
              </SectionTitle>
            </div>
            <div className="col-12">
              <table className="table align-middle">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      {getTranslation(
                        "ADMIN.ORDER.FORM.CADASTER.MEMBERS.TABLE.COLUMN.TITLE_BEFORE",
                        intl
                      )}
                    </th>
                    <th>
                      {getTranslation(
                        "ADMIN.ORDER.FORM.CADASTER.MEMBERS.TABLE.COLUMN.FIRST_NAME",
                        intl
                      )}
                    </th>
                    <th>
                      {getTranslation(
                        "ADMIN.ORDER.FORM.CADASTER.MEMBERS.TABLE.COLUMN.LAST_NAME",
                        intl
                      )}
                    </th>
                    <th>
                      {getTranslation(
                        "ADMIN.ORDER.FORM.CADASTER.MEMBERS.TABLE.COLUMN.TITLE_AFTER",
                        intl
                      )}
                    </th>
                    <th>
                      {getTranslation(
                        "ADMIN.ORDER.FORM.CADASTER.MEMBERS.TABLE.COLUMN.SEX",
                        intl
                      )}
                    </th>
                    <th>
                      {getTranslation(
                        "ADMIN.ORDER.FORM.CADASTER.MEMBERS.TABLE.COLUMN.ADDRESS",
                        intl
                      )}
                    </th>
                    <th>
                      {getTranslation(
                        "ADMIN.ORDER.FORM.CADASTER.MEMBERS.TABLE.COLUMN.SHARE",
                        intl
                      )}
                    </th>
                    <th>
                      {getTranslation(
                        "ADMIN.ORDER.FORM.CADASTER.MEMBERS.TABLE.COLUMN.UNIT",
                        intl
                      )}
                    </th>
                    <th>
                      {getTranslation(
                        "ADMIN.ORDER.FORM.CADASTER.MEMBERS.TABLE.COLUMN.ACTION",
                        intl
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(initialSvjValues.cadastreMembers || []).map(
                    (member: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            <CheckBoxColumn
                              name={"isMainUser"}
                              changeValue={(e: any) => {
                                changeMainUser(e, index);
                              }}
                              value={member.mainUser}
                              readOnly={false}
                              columnLabel={false}
                            />
                          </td>
                          <td>
                            <div
                              className={`${
                                member.remove ? "opacity-30" : null
                              }`}
                            >
                              <InputSimple
                                value={member.titleBefore}
                                name={"titleBefore"}
                                changeValue={(e: any) =>
                                  changeOwnersValue(e, index)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div
                              className={`${
                                member.remove ? "opacity-30" : null
                              }`}
                            >
                              <InputSimple
                                value={member.firstName}
                                name={"firstName"}
                                changeValue={(e: any) =>
                                  changeOwnersValue(e, index)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div
                              className={`${
                                member.remove ? "opacity-30" : null
                              }`}
                            >
                              <InputSimple
                                value={member.lastName}
                                name={"lastName"}
                                changeValue={(e: any) =>
                                  changeOwnersValue(e, index)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div
                              className={`${
                                member.remove ? "opacity-30" : null
                              }`}
                            >
                              <InputSimple
                                value={member.titleAfter}
                                name={"titleAfter"}
                                changeValue={(e: any) =>
                                  changeOwnersValue(e, index)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div
                              className={`${
                                member.remove ? "opacity-30" : null
                              }`}
                              style={{ width: "120px" }}
                            >
                              <SelectColumn
                                values={member}
                                name={"sex"}
                                placeholder={getTranslation(
                                  "USER.FORM.PLACEHOLDER",
                                  intl
                                )}
                                formik={formik}
                                disableMargin={true}
                                required={false}
                                changeValue={(e: any) =>
                                  changeOwnersValue(e, index)
                                }
                                data={getDefaultSexOptions(intl)}
                                readOnly={initialGlobalValues.readOnly}
                                option={{
                                  value: "value",
                                  name: function (value: any) {
                                    return value["label"];
                                  },
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div
                              className={`${
                                member.remove ? "opacity-30" : null
                              }`}
                            >
                              <InputSimple
                                value={member.fullAddress}
                                name={"fullAddress"}
                                changeValue={(e: any) =>
                                  changeOwnersValue(e, index)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div
                              className={`${
                                member.remove ? "opacity-30" : null
                              }`}
                            >
                              <InputSimple
                                value={member.share}
                                name={"share"}
                                changeValue={(e: any) =>
                                  changeOwnersValue(e, index)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div
                              className={`${
                                member.remove ? "opacity-30" : null
                              }`}
                            >
                              <InputSimple
                                value={member.unit}
                                name={"unit"}
                                changeValue={(e: any) =>
                                  changeOwnersValue(e, index)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <DefaultButton
                              className={`btn btn-light-${
                                member.remove ? "warning" : "danger"
                              } btn-sm`}
                              onClick={() => {
                                const old = initialSvjValues.cadastreMembers;
                                let isRemove = true;
                                // @ts-ignore
                                if (
                                  old[index] &&
                                  // @ts-ignore
                                  old[index]?.remove !== undefined &&
                                  // @ts-ignore
                                  old[index]?.remove === true
                                ) {
                                  isRemove = false;
                                }
                                // @ts-ignore
                                old[index] = {
                                  // @ts-ignore
                                  ...old[index],
                                  // @ts-ignore
                                  remove: isRemove,
                                };
                                setInitialSvjValues({
                                  ...initialSvjValues,
                                  cadastreMembers: old,
                                });
                              }}
                            >
                              {member.remove ? (
                                <i className="bi bi-arrow-repeat"></i>
                              ) : (
                                <i className="bi bi-trash"></i>
                              )}
                            </DefaultButton>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getStepContent = (step: any) => {
    switch (step) {
      case 0:
        return <div>{renderStep0()}</div>;
      case 1:
        return <div>{renderStep1()}</div>;
      case 2:
        return <div>{renderStep2()}</div>;
      case 3:
        return <div>{renderStep4()}</div>;
      case 4:
        return <div>{renderStep5()}</div>;
      case 5:
        return <div>{renderStep3()}</div>;
    }
  };

  const changeUserValue = (e: any) => {
    setInitialUserValues({
      ...initialUserValues,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="d-flex align-items-center flex-column flex-column-fluid p-10 w-100">
      <PageTitle pageButtons={[]} linkBack={`/objednavky`}>
        {getTranslation("ADMIN.MENU.ORDERS", intl)}
      </PageTitle>
      <CardLayout>
        <form
          className="form w-100"
          onSubmit={formik.handleSubmit}
          noValidate
          id="order_form"
          key={initialGlobalValues.key}
        >
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={CustomStepIcon}>
                  {label}
                </StepLabel>

                <StepContent TransitionProps={{ unmountOnExit: true }}>
                  <div className="mx-auto w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                    <div className="d-flex flex-column fv-row fv-plugins-icon-container">
                      {getStepContent(index)}
                    </div>
                    <div
                      className={
                        "w-100 d-inline-flex justify-content-between mt-3"
                      }
                    >
                      <DefaultButton
                        className={"btn btn-light-danger btn-sm"}
                        type={"button"}
                        onClick={handleBack}
                      >
                        {getTranslation("ORDER.FORM.BUTTON.BACK", intl)}
                      </DefaultButton>
                      <DefaultButton
                        className={`btn ${
                          index === steps.length - 1
                            ? "btn-success"
                            : "btn-primary"
                        } btn-sm`}
                        type={"submit"}
                      >
                        {index === steps.length - 1
                          ? getTranslation("ORDER.FORM.BUTTON.SEND", intl)
                          : getTranslation("ORDER.FORM.BUTTON.NEXT", intl)}
                      </DefaultButton>
                    </div>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </form>
      </CardLayout>
    </div>
  );
});

export default AdminOrderCheck;
