export const DEFAULT_MEMBER_ROLE = 'member'

export const ROLE_MEMBER = 'member'
export const ROLE_MEMBER_COMMITTEE = 'memberCommittee'
export const ROLE_MAYOR_COMMITTEE = 'mayorCommittee'
export const ROLE_SUB_MAYOR_COMMITTEE = 'subMayorCommittee'
export const ROLE_AUTHORIZED_PERSON = 'authorizedPerson'
export const ROLE_MANAGEMENT_COMPANY = 'managementCompany'

export const ROLE_EXTERNAL_MEMBER_COMMITTEE = 'external_memberCommittee'
export const ROLE_EXTERNAL_MAYOR_COMMITTEE = 'external_mayorCommittee'
export const ROLE_EXTERNAL_SUB_MAYOR_COMMITTEE = 'external_subMayorCommittee'
export const ROLE_EXTERNAL_AUTHORIZED_PERSON = 'external_authorizedPerson'

export const ROLE_AUDIT_COMMISSION_COMMITTEE = 'audit_commission_mayorCommittee'
export const ROLE_AUDIT_SUB_COMMISSION_COMMITTEE = 'audit_commission_subMayorCommittee'
export const ROLE_AUDIT_COMMISSION_MEMBER = 'audit_commission_member'
export const ROLE_INSPECTOR = 'inspector'

export const LIST_EXTERNAL_ROLES = [
  ROLE_EXTERNAL_MEMBER_COMMITTEE,
  ROLE_EXTERNAL_MAYOR_COMMITTEE,
  ROLE_EXTERNAL_SUB_MAYOR_COMMITTEE,
  ROLE_EXTERNAL_AUTHORIZED_PERSON,
]
export const LIST_CRUD_ROLES = [
  'superAdmin',
  ROLE_EXTERNAL_MEMBER_COMMITTEE,
  ROLE_EXTERNAL_MAYOR_COMMITTEE,
  ROLE_EXTERNAL_SUB_MAYOR_COMMITTEE,
  ROLE_MEMBER_COMMITTEE,
  ROLE_MAYOR_COMMITTEE,
  ROLE_SUB_MAYOR_COMMITTEE,
  ROLE_MANAGEMENT_COMPANY,
]

export const COMMITTEE_ROLES = [
  ROLE_MEMBER_COMMITTEE,
  ROLE_MAYOR_COMMITTEE,
  ROLE_SUB_MAYOR_COMMITTEE,
  ROLE_EXTERNAL_MEMBER_COMMITTEE,
  ROLE_EXTERNAL_MAYOR_COMMITTEE,
  ROLE_EXTERNAL_SUB_MAYOR_COMMITTEE,
]

export const AUTHORIZED_PERSON_TYPES: any = [
  {value: 'husband', label: 'USER.AUTHORIZED_PERSON.TYPE.HUSBAND'},
  {value: 'wife', label: 'USER.AUTHORIZED_PERSON.TYPE.WIFE'},
  {value: 'partner', label: 'USER.AUTHORIZED_PERSON.TYPE.PARTNER'},
  {value: 'daughter', label: 'USER.AUTHORIZED_PERSON.TYPE.DAUGHTER'},
  {value: 'son', label: 'USER.AUTHORIZED_PERSON.TYPE.SUN'},
  {value: 'mother', label: 'USER.AUTHORIZED_PERSON.TYPE.MOTHER'},
  {value: 'father', label: 'USER.AUTHORIZED_PERSON.TYPE.FATHER'},
  {value: 'familyMember', label: 'USER.AUTHORIZED_PERSON.TYPE.FAMILY'},
  {value: 'renter', label: 'USER.AUTHORIZED_PERSON.TYPE.TENANT'},
  {
    value: 'authorizePerson',
    label: 'USER.AUTHORIZED_PERSON.TYPE.AUTHORIZED_PERSON',
  },
  {value: 'notSpecify', label: 'USER.AUTHORIZED_PERSON.TYPE.NONE'},
]
export const LIST_ROLES = [
  {
    id: ROLE_MEMBER,
    value: ROLE_MEMBER,
    translate: true,
    order: 0,
    title: 'USER.ROLES.MEMBER',
  },
  {
    id: ROLE_MEMBER_COMMITTEE,
    value: ROLE_MEMBER_COMMITTEE,
    translate: true,
    order: 1,
    title: 'USER.ROLES.ROLE_MEMBER_COMMITTEE',
  },
  {
    id: ROLE_MAYOR_COMMITTEE,
    value: ROLE_MAYOR_COMMITTEE,
    translate: true,
    order: 2,
    title: 'USER.ROLES.ROLE_MAYOR_COMMITTEE',
  },
  {
    id: ROLE_SUB_MAYOR_COMMITTEE,
    value: ROLE_SUB_MAYOR_COMMITTEE,
    translate: true,
    order: 3,
    title: 'USER.ROLES.ROLE_SUB_MAYOR_COMMITTEE',
  },
  {
    id: ROLE_AUTHORIZED_PERSON,
    value: ROLE_AUTHORIZED_PERSON,
    translate: true,
    order: 4,
    title: 'USER.ROLES.ROLE_AUTHORIZED_PERSON',
  },
  {
    id: ROLE_MANAGEMENT_COMPANY,
    value: ROLE_MANAGEMENT_COMPANY,
    translate: true,
    order: 5,
    title: 'USER.ROLES.ROLE_MANAGEMENT_COMPANY',
  },
  {
    id: ROLE_EXTERNAL_MEMBER_COMMITTEE,
    value: ROLE_EXTERNAL_MEMBER_COMMITTEE,
    translate: true,
    order: 6,
    title: 'USER.ROLES.ROLE_EXTERNAL_MEMBER_COMMITTEE',
  },
  {
    id: ROLE_EXTERNAL_MAYOR_COMMITTEE,
    value: ROLE_EXTERNAL_MAYOR_COMMITTEE,
    translate: true,
    order: 7,
    title: 'USER.ROLES.ROLE_EXTERNAL_MAYOR_COMMITTEE',
  },
  {
    id: ROLE_EXTERNAL_SUB_MAYOR_COMMITTEE,
    value: ROLE_EXTERNAL_SUB_MAYOR_COMMITTEE,
    translate: true,
    order: 8,
    title: 'USER.ROLES.ROLE_EXTERNAL_SUB_MAYOR_COMMITTEE',
  },
  {
    id: ROLE_EXTERNAL_AUTHORIZED_PERSON,
    value: ROLE_EXTERNAL_AUTHORIZED_PERSON,
    translate: true,
    order: 9,
    title: 'USER.ROLES.ROLE_EXTERNAL_AUTHORIZED_PERSON',
  },
  {
    id: ROLE_AUDIT_COMMISSION_COMMITTEE,
    value: ROLE_AUDIT_COMMISSION_COMMITTEE,
    translate: true,
    order: 10,
    title: 'USER.ROLES.ROLE_AUDIT_COMMISSION_COMMITTEE',
  },
  {
    id: ROLE_AUDIT_SUB_COMMISSION_COMMITTEE,
    value: ROLE_AUDIT_SUB_COMMISSION_COMMITTEE,
    translate: true,
    order: 11,
    title: 'USER.ROLES.ROLE_AUDIT_SUB_COMMISSION_COMMITTEE',
  },
  {
    id: ROLE_AUDIT_COMMISSION_MEMBER,
    value: ROLE_AUDIT_COMMISSION_MEMBER,
    translate: true,
    order: 12,
    title: 'USER.ROLES.ROLE_AUDIT_COMMISSION_MEMBER',
  },
  {
    id: ROLE_INSPECTOR,
    value: ROLE_INSPECTOR,
    translate: true,
    order: 13,
    title: 'USER.ROLES.ROLE_INSPECTOR',
  },
]
