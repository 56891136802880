import { observer } from "mobx-react";
import * as React from "react";
import { FC } from "react";
import { useIntl } from "react-intl";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import UserStore from "../../Stores/User";
import { getRandomKey, getTranslation } from "../../Helpers/Default";
import paginationFactory from "react-bootstrap-table2-paginator";
import "./style.scss";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { setRoutesParamArray } from "../../Helpers/Routes";
import { useHistory, useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap-v5";
import { DefaultButton } from "../Buttons/DefaultButton";
import EmptyText from "../EmptyText";
import _ from "lodash";

const DefaultTableMui: FC<{
  showColumnFilters: any;
  columns: any;
  data: any;
  emptyTableText?: string;
  filters?: any;
  allSize?: number;
  pagination?: boolean;
  allowedSelectRow?: boolean;
}> = observer((props) => {
  const intl = useIntl();
  let columnsData: any = _.compact(props.columns);
  const allSize = props?.data.length || 0;
  const location = useLocation();
  const history = useHistory();
  const allowedSelectRow =
    props.allowedSelectRow !== undefined ? props.allowedSelectRow : false;
  let sizePerPage = parseInt(
    location.search.split("sizePerPage=")[1]?.split("&")[0] || allSize
  );
  sizePerPage = sizePerPage === 0 ? allSize : sizePerPage;

  const page = parseInt(
    location.search.split("page=")[1]?.split("&")[0] || "1"
  );
  const showPagination =
    props.pagination !== undefined ? props.pagination : true;
  if (!props.showColumnFilters) {
    columnsData = _.compact(props.columns).map((column: any) => {
      const newColumn = { ...column };

      delete newColumn.filterValue;
      delete newColumn.filter;

      return newColumn;
    });
  }

  // function afterFilter(newResult: any, newFilters: any) {
  //   console.log(newResult, newFilters, filters)
  //   let filter: any = {}
  //   for (let i in newFilters) {
  //     filter[i] = newFilters[i].filterValue
  //   }
  //   // setRoutesParam('filters', JSON.stringify(filter), history, location)
  // }
  let pagination = {};
  if (showPagination && allSize > 0) {
    pagination = {
      pagination: paginationFactory({
        page: page,
        sizePerPageRenderer: ({
          options,
          currSizePerPage,
          onSizePerPageChange,
        }) => {
          let foundCurrentText = options.find(
            (option: any) =>
              option.page.toString() === currSizePerPage.toString()
          );
          if (!foundCurrentText) {
            foundCurrentText = options[options.length - 1];
          }

          return (
            <Dropdown navbar={true} drop={"right"}>
              <Dropdown.Toggle variant="link" bsPrefix="p-0">
                <span>
                  <DefaultButton
                    size={"sm"}
                    color={"outline-primary btn-outline btn-active-primary"}
                    className={"py-2"}
                    key={getRandomKey()}
                  >
                    {foundCurrentText ? foundCurrentText.text : ""}
                  </DefaultButton>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={"z-index-98 py-0 mt-0 ms-1"}>
                {options.map((option: any) => {
                  return (
                    <Dropdown.Item
                      href="#"
                      className={
                        "btn-sm btn-active-light-secondary py-2 text-muted text-black"
                      }
                      onClick={() => {
                        onSizePerPageChange(option.page);
                      }}
                    >
                      {option.text}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          );
        },
        // paginationTotalRenderer: (from, to, size) => {
        //   return ()
        // },
        alwaysShowAllBtns: true,
        onPageChange: (page, sizePerPage) => {
          setRoutesParamArray(
            [
              { name: "page", value: (page || 0).toString() },
              { name: "sizePerPage", value: (sizePerPage || 0).toString() },
            ],
            history,
            location
          );
        },
        onSizePerPageChange: (page, sizePerPage) => {
          setRoutesParamArray(
            [
              { name: "page", value: (sizePerPage || 0).toString() },
              { name: "sizePerPage", value: (page || 0).toString() },
            ],
            history,
            location
          );
        },
        sizePerPage: sizePerPage,
        sizePerPageList: [
          { text: "25", value: 25 },
          { text: "50", value: 50 },
          {
            text: getTranslation("TABLE.PAGINATION.ALL", intl),
            value: allSize || 0,
          },
        ],
      }),
    };
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  };

  return (
    <div
      className={"table-responsivew w-100"}
      data-show-print="true"
      key={"table_" + allSize}
    >
      <ToolkitProvider keyField="id" data={props.data} columns={columnsData}>
        {(props: any) => (
          <>
            {/*<h3>Input something at below input field:</h3>*/}
            {/*<SearchBar {...props.searchProps} />*/}
            {/*<hr />*/}
            <BootstrapTable
              showPagination={false}
              {...pagination}
              {...props.baseProps}
              // columns={columnsData}
              lang={UserStore.selectLang}
              locale={"cs-CZ"}
              filter={filterFactory({})}
              filterPosition="top"
              noDataIndication={() => {
                return <EmptyText center={true} text={props.emptyTableText} />;
              }}
              wrapperClasses={"dataTables_wrapper dt-bootstrap4 no-footer"}
              classes={
                "main-table table table-responsive overflow-hidden align-middle table-row-dashed fs-6 gy-5 mb-0 dataTable no-footer w-100"
              }
              selectRow={allowedSelectRow ? selectRow : undefined}
              filtersClasses={"filter-class"}
              // bodyClasses={'fw-semibold text-gray-600'}
              headerWrapperClasses={"text-start fw-bold-custom fs-7 gs-0"}
            />
          </>
        )}
      </ToolkitProvider>
    </div>
  );
});

export default DefaultTableMui;
