import {action, makeAutoObservable, observable} from 'mobx'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {REQ_TYPE} from '../../Constants/Request'
import {createFormData, downloadBlobPdf, hasModule} from '../../Helpers/Default'
import {EVENTS_MODULE} from '../../Constants/Modules'

class EventStore {
  labels = []
  events: any = []
  calendars: any = []
  isLoading: boolean = false
  isCreating: boolean = false
  isUpdating: boolean = false
  isDeleting: boolean = false
  isSearching: boolean = false
  isGetting: boolean = false

  constructor() {
    makeAutoObservable(this, {
      setValue: action,
      isCreating: observable,
    })
  }

  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }

  async getAllCalendars() {
    const res = await apiRequest(
      '/api/v2/event/calendar',
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(null),
      this,
      'login_request'
    )

    if (res.status === 200) {
      this.setValue('calendars', res.data)
    }
  }

  async getAll(calendarId: string = 'main') {
    let changeData = []

    const res = await apiRequest(
      `/api/v2/event/calendar/${calendarId}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(null),
      this,
      'login_request'
    )

    if (res.status === 200) {
      changeData = res.data.map((event: any) => {
        let data = {
          ...event,
          url: '/kalendar-udalosti/' + event.id,
          // className: 'kt-badge-' + event.label.type,
          isAllDay: event.allDay,
        }

        if (event.allDay) {
          const date = new Date(data.end)
          date.setDate(date.getDate() + 1)
          data = {
            ...data,
            end: date.toISOString(),
            // allDay: false,
            // setAllDay: true,
          }
        }

        return data
      })
    }
    this.setValue('events', changeData)
    return changeData
  }

  async getLabels() {
    const res = await apiRequest(
      '/api/v2/event/label',
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(null),
      this,
      'login_request'
    )

    if (res.status === 200) {
      this.setValue('labels', res.data)
    }
  }

  async create(data: any, attachments: any[]) {
    this.setValue('isCreating', true)
    const res = await apiRequest(
      '/api/v2/event',
      REQ_TYPE.post,
      data,
      // @ts-ignore
      getHeaders(null),
      this,
      'login_request'
    )

    for (const file of attachments) {
      const formData = createFormData(file)

      const res2 = await apiRequest(
        `/api/v2/event/upload/${res.data.id}?file_name=${encodeURIComponent(file.originName)}`,
        REQ_TYPE.post,
        // @ts-ignore
        formData,
        // @ts-ignore
        getHeaders(null, {'content-type': 'multipart/form-data'}),
        this,
        ''
      )
    }

    this.setValue('isCreating', false)
    if (res.status === 200) {
      return res.data
    }
  }

  async get(id: string) {
    const res = await apiRequest(
      '/api/v2/event/' + id,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(null),
      this,
      'login_request'
    )

    if (res.status === 200) {
      return res.data
    }
  }

  async update(id: string | null, data: any, attachments: any[]) {
    const res = await apiRequest(
      '/api/v2/event/' + id,
      REQ_TYPE.put,
      data,
      // @ts-ignore
      getHeaders(null),
      this,
      'login_request'
    )

    for (const file of attachments) {
      const formData = createFormData(file)

      const res2 = await apiRequest(
        `/api/v2/event/upload/${data.id}?file_name=${encodeURIComponent(file.originName)}`,
        REQ_TYPE.post,
        // @ts-ignore
        formData,
        // @ts-ignore
        getHeaders(null, {'content-type': 'multipart/form-data'}),
        this,
        ''
      )
    }

    if (res.status === 200) {
      return res.data
    }
  }

  async createCalendar(data: any) {
    const res = await apiRequest(
      '/api/v2/event/calendar',
      REQ_TYPE.post,
      data,
      // @ts-ignore
      getHeaders(null),
      this,
      'login_request'
    )

    return res.status === 200
  }

  async updateCalendar(id: string, data: any) {
    const res = await apiRequest(
      '/api/v2/event/calendar/' + id,
      REQ_TYPE.put,
      data,
      // @ts-ignore
      getHeaders(null),
      this,
      'login_request'
    )

    return res.status === 200
  }

  async deleteCalendar(id: string) {
    const res = await apiRequest(
      '/api/v2/event/calendar/' + id,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(null),
      this,
      'login_request'
    )
    return res.status === 200
  }

  async delete(id: any, type: string) {
    const res = await apiRequest(
      `/api/v2/event/${id}?type=${type}`,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(null),
      this,
      'login_request'
    )
    return res.status === 200
  }

  async search(text: string) {
    if (!hasModule(EVENTS_MODULE)) {
      return []
    }
    this.setValue('isSearching', true)
    const res = await apiRequest(
      `/api/v2/event/search/${encodeURIComponent(text)}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    return res.data ?? []
  }

  async print(id: string | null, name: string | null) {
    if (this.isGetting) {
      return null
    }

    this.setValue('isGetting', true)
    const res = await apiRequest(
      `/api/v2/event/${id}/print`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isGetting', false)
    downloadBlobPdf(res.data, name + '.pdf')
    return null
  }
}
export default new EventStore()
