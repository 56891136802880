import {makeAutoObservable} from 'mobx'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {REQ_TYPE} from '../../Constants/Request'
import {showNotification, showResponseNotificationMessage} from '../../Helpers/Notification'
import {NOTIFICATION_TYPE} from '../../Constants/Notification'
import {createFormData, downloadBlobPdf, hasModule} from '../../Helpers/Default'
import {MEETING_OWNERS_MODULE, MESSAGES_MODULE} from '../../Constants/Modules'

class MessagesStore {
  constructor() {
    makeAutoObservable(this)
  }
  messages = []
  actualCount = 0
  oldCount = 0
  pinedCount = 0
  archiveCount = 0
  conceptCount = 0
  plannedCount = 0
  messageLabels = []
  defaultLabel = null
  isLoading = false
  isRemoving = false
  isCreating = false
  isGetting = false
  isSearching = false

  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }

  async getLabels() {
    const res = await apiRequest(
      `/api/v2/message/labels`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    this.setValue('messageLabels', res.data ?? [])
  }

  async getAll(tab: string) {
    this.setValue('isLoading', true)
    const res = await apiRequest(
      `/api/v2/message?tab=${tab}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    if (res?.data?.messages) {
      this.setValue('messages', res.data?.messages)
      this.setValue('actualCount', res.data?.actualCount)
      this.setValue('oldCount', res.data?.oldCount)
      this.setValue('pinedCount', res.data?.pinedCount)
      this.setValue('archiveCount', res.data?.archiveCount)
      this.setValue('conceptCount', res.data?.conceptCount)
      this.setValue('plannedCount', res.data?.plannedCount)
    } else {
      this.setValue('messages', [])
    }
    this.setValue('isLoading', false)
    return res.data
  }

  async create(data: any, attachments: any) {
    if (this.isCreating) {
      return null
    }

    this.setValue('isCreating', true)
    const res = await apiRequest(
      '/api/v2/message',
      REQ_TYPE.post,
      // @ts-ignore
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    for (const file of attachments) {
      const formData = createFormData(file)

      const res2 = await apiRequest(
        `/api/v2/message/upload/${res.data.id}`,
        REQ_TYPE.post,
        // @ts-ignore
        formData,
        // @ts-ignore
        getHeaders(null, {'content-type': 'multipart/form-data'}),
        this,
        ''
      )
    }

    this.setValue('isCreating', false)
    return res.status === 200
  }

  async update(data: any, attachments: any) {
    if (this.isCreating) {
      return null
    }

    this.setValue('isCreating', true)
    const res = await apiRequest(
      `/api/v2/message/${data.id}`,
      REQ_TYPE.put,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    for (const file of attachments) {
      const formData = createFormData(file)

      const res2 = await apiRequest(
        `/api/v2/message/upload/${data.id}`,
        REQ_TYPE.post,
        // @ts-ignore
        formData,
        // @ts-ignore
        getHeaders(null, {'content-type': 'multipart/form-data'}),
        this,
        ''
      )
    }

    this.setValue('isCreating', false)
    return res.status === 200
  }

  async removeGroup(ids: any[]) {
    if (this.isRemoving) {
      return null
    }

    this.setValue('isRemoving', true)
    const res = await apiRequest(
      `/api/v2/message/group`,
      REQ_TYPE.post,
      // @ts-ignore
      {ids},
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isRemoving', false)
    return res.status === 200
  }

  async remove(id: string) {
    if (this.isRemoving) {
      return null
    }

    this.setValue('isRemoving', true)
    const res = await apiRequest(
      `/api/v2/message/${id}`,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isRemoving', false)
    return res.status === 200
  }

  async archiveBulk(ids: any[]) {
    if (this.isRemoving) {
      return null
    }

    this.setValue('isRemoving', true)
    const res = await apiRequest(
      `/api/v2/message/archiveBulk`,
      REQ_TYPE.post,
      // @ts-ignore
      {ids},
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isRemoving', false)
    return res.status === 200
  }
  async archive(id: string) {
    if (this.isRemoving) {
      return null
    }

    this.setValue('isRemoving', true)
    const res = await apiRequest(
      `/api/v2/message/${id}/archive`,
      REQ_TYPE.put,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isRemoving', false)
    return res.status === 200
  }

  async get(id: string) {
    this.setValue('isGetting', true)
    const res = await apiRequest(
      `/api/v2/message/${id}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isGetting', false)
    if (res?.data) {
      return res.data
    }
    // showNotification(NOTIFICATION_TYPE.danger, 'Nepodarilo se nacist obsah zpravy')
    return null
  }

  async print(id: string | null, name: string | null) {
    if (this.isGetting) {
      return null
    }

    this.setValue('isGetting', true)
    const res = await apiRequest(
      `/api/v2/message/${id}/print`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isGetting', false)
    downloadBlobPdf(res.data, name + '.pdf')
    return null
  }

  async search(text: string) {
    if (!hasModule(MESSAGES_MODULE)) {
      return []
    }
    this.setValue('isSearching', true)
    const res = await apiRequest(
      `/api/v2/message/search/${encodeURIComponent(text)}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isSearching', false)
    return res.data ?? []
  }
}

export default new MessagesStore()
