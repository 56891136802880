import {action, makeAutoObservable} from 'mobx'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {REQ_TYPE} from '../../Constants/Request'
import {createFormData, downloadFile, hasModule} from '../../Helpers/Default'
import {CONTACT_MODULE, DOCUMENTS_MODULE} from '../../Constants/Modules'

class DocumentStore {
  page = 0
  total_pages = 0
  total_items = 0
  folder_id = null
  data = []
  files = []
  folders = []
  structures = []
  isLoading: boolean = false
  user: any = null
  isCreating: boolean = false
  isUpdating: boolean = false
  isDownloading: boolean = false
  isSearching: boolean = false

  constructor() {
    makeAutoObservable(this, {
      setValue: action,
    })
  }

  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }

  async getAll() {
    const res = await apiRequest(
      '/api/v1/document/folder_list?page=' + this.page + '&folder_id=' + this.folder_id,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    if (res.status === 200) {
      this.setValue('files', res.data.files)
      this.setValue('folders', res.data.folders)
      this.setValue('total_pages', res.data.totalPages)
      this.setValue('total_items', res.data.totalItems)

      let data = [...res.data.folders, ...res.data.files]
      // data.sort((item, item2) => {
      //   if ('type' in item) {
      //     return 1
      //   }
      //   return -1
      // })
      // console.log(data)
      this.setValue('data', data)
    }
  }

  async create(file: any) {
    const formData = createFormData(file)

    const res = await apiRequest(
      '/api/v1/document/file/create',
      REQ_TYPE.post,
      // @ts-ignore
      formData,
      // @ts-ignore
      getHeaders(null, {'content-type': 'multipart/form-data'}),
      this,
      'login_request'
    )

    return res.status === 200
  }

  async get(id: any) {
    const res = await apiRequest(
      `/api/v2/file/${id}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    return res.data
  }

  async update(id: any, data: any) {
    const res = await apiRequest(
      `/api/v2/file/${id}`,
      REQ_TYPE.put,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    return res.status === 200
  }

  async remove(id: any) {
    const res = await apiRequest(
      `/api/v2/file/${id}`,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    return res.status === 200
  }

  async download(id: any, fileName: any, type = 'file') {
    this.setValue('isDownloading', true)
    const res = await apiRequest(
      `/api/v2/file/${id}/download?type=${type}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )
    this.setValue('isDownloading', false)
    if (res.data) {
      downloadFile(res.data, fileName)
    }
    return res.status === 200
  }

  async search(text: string) {
    if (!hasModule(DOCUMENTS_MODULE)) {
      return []
    }
    this.setValue('isSearching', true)
    const res = await apiRequest(
      `/api/v2/file/search/${encodeURIComponent(text)}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isSearching', false)
    return res.data ?? []
  }
}
export default new DocumentStore()
