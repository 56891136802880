import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {getTranslation, hasModule} from '../../Helpers/Default'
import {observer} from 'mobx-react'
import InvoiceStore from '../../Stores/Invoice'

const DashboardWrapper: FC = observer(() => {
  const intl = useIntl()

  useEffect(() => {
    async function getData() {
      // DashboardStore.getData()
      // UserStore.getAll()
      // setCommittee(toJS(SvjStore.committees))
    }

    getData()
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}>{getTranslation('MENU.DASHBOARD', intl)}</PageTitle>

    </>
  )
})

export {DashboardWrapper}
