import React from 'react'
import {useIntl} from 'react-intl'
import SvjStore from '../../../../app/Stores/Svj'
import {observer} from 'mobx-react'
import {TooltipDefault} from '../../../../app/Components/Tooltip'
import ModalHeader from 'react-bootstrap-v5/lib/cjs/ModalHeader'
import {getTranslation} from '../../../../app/Helpers/Default'
import {DefaultModal} from '../../../../app/Components/DefaultModal'
import {ModalBody} from 'react-bootstrap-v5'
import SelectSVJ from '../../../../app/Components/SelectSVJ'
import {KTSVG} from '../../../helpers'

export const MenuInner = observer(() => {
  const intl = useIntl()
  const [showModal, setShowModal] = React.useState(false)

  const handleClose = () => {
    setShowModal(false)
  }
  return (
    <>
      <div className='menu-item'>
        <span className='menu-title fw-boldest'>
          <div>
            {/*<TooltipDefault*/}
            {/*  position={'bottom'}*/}
            {/*  tooltip={intl.formatMessage({id: 'MENU.TOP.CHANGE_SVJ'})}*/}
            {/*>*/}
            {/*  <div*/}
            {/*    className='cursor-pointer text-hover-primary'*/}
            {/*    onClick={() => {*/}
            {/*      setShowModal(true)*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <KTSVG*/}
            {/*      path='/media/icons/duotune/abstract/abs015.svg'*/}
            {/*      className='svg-icon-1x me-2'*/}
            {/*    />*/}
            {/*    {SvjStore.data.nickname !== null && SvjStore.data.nickname !== ''*/}
            {/*      ? SvjStore.data.nickname*/}
            {/*      : SvjStore.data.name}*/}
            {/*  </div>*/}
            {/*</TooltipDefault>*/}
          </div>
        </span>
      </div>

      <DefaultModal show={showModal} onHide={() => {}} width={'mw-800px'}>
        <ModalHeader>
          <h3>{getTranslation('SVJ.MODAL.SELECT_SVJ.TITLE', intl)}</h3>
          <div className='btn btn-icon btn-sm btn-light-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </ModalHeader>
        <ModalBody>
          <SelectSVJ />
        </ModalBody>
      </DefaultModal>
    </>
  )
})
