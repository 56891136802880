import { observer } from "mobx-react";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { hasCrudRole } from "../../../Helpers/User";
import moment from "moment";
import { DEFAULT_DATE_TIME_FORMAT } from "../../../Constants/Defatul";
import {
  GALLERY_MODULE,
  MESSAGES_MODULE,
  ORDER_MODULE,
} from "../../../Constants/Modules";
import { ActionButtonsArea } from "../../../Components/ActionButtonsArea";
import createColumn from "../../../Components/DefaultTableMui/Columns";
import TopTableTabs from "../../../Components/Table/TopTableTabs";
import { getRandomKey, getTranslation } from "../../../Helpers/Default";
import DefaultTableMui from "../../../Components/DefaultTableMui";
import CardLayout from "../../../Components/CardLayout";
import { useHistory, useLocation } from "react-router-dom";
import { useTableFilters } from "../../../Components/DefaultTableMui/Filters";
import { TableTabFilterEffect } from "../../../Components/TableTabFilterEffect";
import SectionTitle from "../../../Components/SectionTitle";
import OrderStore from "../../../Stores/Order";
import { DefaultBadge } from "../../../Components/Badge";
import { LinkButton } from "../../../Components/LinkButton";
import { toJS } from "mobx";
import copy from "copy-to-clipboard";
import { confirmDialog } from "../../../Helpers/ConfirmDialog";

const FILTER_ENUM = {
  all: "all",
};
const OrderAdmin: FC<{}> = observer(() => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const { filters, changeFilter, toggleShowFilter, showFilter } =
    useTableFilters();
  const [tableKey, setTableKey] = React.useState(getRandomKey());
  const [selectedFilter, setSelectedFilter] = useState(FILTER_ENUM.all);
  TableTabFilterEffect(setSelectedFilter, FILTER_ENUM.all);

  useEffect(() => {
    async function getData() {
      await OrderStore.getAll();
    }

    getData();
  }, []);

  const handleDownloadAcceptProtocol = async (e: any) => {
    await OrderStore.getAcceptProtocol(e.id);
  };

  const changeValue = (e: any) => {};
  const handleDelete = async (e: any) => {
    if (await OrderStore.delete(e.id)) {
      OrderStore.getAll();
    }
  };
  const goToDetailCommon = (data: any) => {
    history.push(`/objednavky/${data.id}/kontrola`);
  };

  const columnConfigs: any = [
    {
      dataField: "order_number",
      textKey: "ADMIN.ORDER.TABLE.COLUMN.ORDER_NUMBER",
      filterType: "text",
      classes: "cursor-pointer",
      goToDetail: goToDetailCommon,
      formatter: (cellContent: any, row: any) => (
        <div className={"fw-bold-custom d-inline-flex align-items-center"}>
          <span>{row?.variableSymbol ?? "-"}</span>
        </div>
      ),
    },
    {
      dataField: "isFree",
      textKey: "ADMIN.ORDER.TABLE.COLUMN.IS_FREE",
      filterType: "text",
      classes: "cursor-pointer",
      goToDetail: goToDetailCommon,
      formatter: (cellContent: any, row: any) => (
        <div className={"fw-bold-custom d-inline-flex align-items-center"}>
          <span>{row?.isFree ? "Ano" : "ne"}</span>
        </div>
      ),
    },
    {
      dataField: "email",
      textKey: "ADMIN.ORDER.TABLE.COLUMN.EMAIL",
      filterType: "text",
      classes: "cursor-pointer",
      defaultFilterValue: () => filters?.email ?? "",
      onFilterChange: (filterValue: any) => {
        if (filterValue !== filters.email) {
          changeFilter(filterValue, "email");
        }
      },
      filterValueGetter: (cell: any, row: any) => row.email.toLowerCase(),
      goToDetail: goToDetailCommon,
      formatter: (cellContent: any, row: any) => (
        <div className={"fw-bold-custom d-inline-flex align-items-center"}>
          <span>{row?.email ?? "-"}</span>
        </div>
      ),
    },
    {
      dataField: "created",
      textKey: "ADMIN.ORDER.TABLE.COLUMN.CREATED",
      filterType: "test",
      classes: "cursor-pointer",
      defaultFilterValue: () => filters?.created ?? "",
      onFilterChange: (filterValue: any) => {
        if (filterValue !== filters.created) {
          changeFilter(filterValue, "created");
        }
      },
      filterValueGetter: (cell: any, row: any) =>
        moment(row.createdAt).format(DEFAULT_DATE_TIME_FORMAT).toString(),
      goToDetail: goToDetailCommon,
      formatter: (cellContent: any, row: any) => {
        return (
          <span>{moment(row.createdAt).format(DEFAULT_DATE_TIME_FORMAT)}</span>
        );
      },
    },
    {
      dataField: "status",
      textKey: "ADMIN.ORDER.TABLE.COLUMN.STATUS",
      filterType: "text",
      classes: "cursor-pointer",
      goToDetail: goToDetailCommon,
      formatter: (cellContent: any, row: any) => {
        let text = "";
        let color = "primary";

        if (row?.finish) {
          text = getTranslation("ADMIN.ORDER.TABLE.STATUS.FILLED", intl);
          color = "success";
        } else if (row?.wasDeclined) {
          text = getTranslation("ADMIN.ORDER.TABLE.STATUS.DECLINED", intl);
          color = "danger";
        } else {
          text = getTranslation(
            "ADMIN.ORDER.TABLE.STATUS.WAITING_TO_FILL",
            intl
          );
          color = "warning";
        }
        return (
          <DefaultBadge type={"light"} color={color}>
            {text}
          </DefaultBadge>
        );
      },
    },
    {
      dataField: "active",
      textKey: "ADMIN.ORDER.TABLE.COLUMN.ACTIVATE",
      filterType: "text",
      classes: "cursor-pointer",
      goToDetail: goToDetailCommon,
      formatter: (cellContent: any, row: any) => {
        let text = "";
        let color = "primary";

        if (row?.wasCheck) {
          text = getTranslation(
            "ADMIN.ORDER.TABLE.STATUS.ACTIVATION.CHECKED",
            intl
          );
          color = "success";
        } else {
          text = getTranslation(
            "ADMIN.ORDER.TABLE.STATUS.ACTIVATION.NO_CHECKED",
            intl
          );
          color = "warning";
        }

        return <DefaultBadge color={color}>{text}</DefaultBadge>;
      },
    },
    {
      dataField: "care",
      textKey: "ADMIN.ORDER.TABLE.COLUMN.CARE_ABOUT",
      filterType: "text",
      classes: "cursor-pointer",
      goToDetail: goToDetailCommon,
      formatter: (cellContent: any, row: any) => {
        return "-";
      },
    },
    {
      dataField: "action",
      textKey: "BASE.ACTION",
      labelClass: "text-right",
      filterType: null, // No filter for action buttons
      width: hasCrudRole(MESSAGES_MODULE) ? "175px" : "50px",
      filterValueGetter: (cell: any, row: any) => "",
      formatter: (cellContent: any, row: any) => {
        const actionButtons = [
          // {
          //   link: (data: any) => {
          //     return `/objednavky/${data.id}`
          //   },
          //   text: getTranslation('ADMIN.ORDER.TABLE.ACTION.DETAIL', intl),
          //   color: 'warning',
          //   className: 'ms-1',
          //   icon: 'fa fa-search',
          // },
          {
            action: (data: any) => {
              confirmDialog(
                "",
                getTranslation(
                  "ADMIN.ORDER.TABLE.ACTION.NEW_TOKEN_CONFIRM",
                  intl
                ),
                async () => {
                  if (await OrderStore.newToken(data.id)) {
                    OrderStore.getAll();
                  }
                }
              );
            },
            text: getTranslation("ADMIN.ORDER.TABLE.ACTION.NEW_TOKEN", intl),
            color: "warning",
            className: "ms-1",
            icon: "fas fa-sync-alt",
          },
          {
            action: async (data: any) => {
              const text = "https://moje.svjaplikace.cz/objednavka/" + data.url;
              try {
                copy(text);
              } catch (err) {
                console.error("Failed to copy: ", err);
              }
            },
            text: getTranslation("ADMIN.ORDER.TABLE.ACTION.COPY_LINK", intl),
            color: "primary",
            className: "ms-1",
            icon: "fa fa-link",
          },
          {
            link: (data: any) => {
              return `/objednavky/${data.id}/kontrola`;
            },
            text: getTranslation("ADMIN.ORDER.TABLE.ACTION.CHECK_DATA", intl),
            color: "success",
            className: "ms-1",
            icon: "fas fa-check",
            visible: hasCrudRole(ORDER_MODULE),
          },
          {
            action: handleDownloadAcceptProtocol,
            text: "Stahnout akceptacni protokol",
            color: "info",
            className: "ms-1",
            icon: "fa fa-download",
            visible: row?.wasCheck && hasCrudRole(ORDER_MODULE),
          },
          {
            action: handleDelete,
            text: getTranslation("ADMIN.ORDER.TABLE.ACTION.DELETE", intl),
            color: "danger",
            className: "ms-1",
            icon: "fa fa-trash",
            visible: hasCrudRole(ORDER_MODULE),
          },
        ];

        return <ActionButtonsArea data={row} buttons={actionButtons} />;
      },
    },
  ];

  const selectOptions = {
    visibility: [
      {
        label: getTranslation("GALLERY.VISIBILITY.PUBLIC", intl),
        value: "true",
      },
      {
        label: getTranslation("GALLERY.VISIBILITY.COMMITTEE", intl),
        value: "false",
      },
    ],
  };

  const columns = Object.keys(columnConfigs).map((key) =>
    createColumn(columnConfigs[key], filters, changeFilter, intl, {
      selectOptions,
    })
  );

  const tabs = [
    {
      id: "all",
      title: getTranslation("ADMIN.ORDER.TABLE.FILTER.ALL", intl),
      icon: "la la-hourglass-start",
      visible: true,
    },
    {
      id: "not_accepted",
      title: getTranslation("ADMIN.ORDER.TABLE.FILTER.NOT_ACCEPTED", intl),
      icon: "la la-hourglass-start",
      visible: true,
    },
  ];
  const pageButtons = [];
  if (hasCrudRole(ORDER_MODULE)) {
    pageButtons.push(
      <LinkButton
        link={"/objednavky/novy"}
        icon={<i className="fas fa-plus-circle"></i>}
        text={getTranslation("ADMIN.ORDER.TABLE.ACTION.NEW", intl)}
      />
    );
  }

  return (
    <>
      <SectionTitle buttons={pageButtons}>
        {getTranslation("ADMIN.ORDER.TITLE", intl)}
      </SectionTitle>
      <CardLayout
        header_class={"card-header-stretch "}
        header={
          <TopTableTabs
            handleChangeFilter={() => {}}
            selectedFilter={selectedFilter}
            showFilter={showFilter}
            setShowFilter={(e: any) => {
              toggleShowFilter(e);
              setTableKey(getRandomKey);
            }}
            tabs={tabs}
          />
        }
      >
        <DefaultTableMui
          key={tableKey}
          data={OrderStore.data}
          columns={columns}
          showColumnFilters={showFilter}
          filters={filters}
        />
      </CardLayout>
    </>
  );
});

export default OrderAdmin;
