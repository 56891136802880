import {action, makeAutoObservable} from 'mobx'
import {
  deleteLocalStorageValue,
  getLocalStorageValue,
  setLocalStorageValue,
} from '../../Helpers/LocalStorage'
import {getHeaders, apiRequest} from '../../Helpers/Request'
import {REQ_TYPE} from '../../Constants/Request'
import SvjStore from './../Svj'
import UserStore from './../User'

class AuthStore {
  isLoading: boolean = false
  isAuthorize: boolean = false
  token: any = null
  login_request: boolean = false
  searchByEmail = ''

  constructor() {
    makeAutoObservable(this, {
      getToken: action,
      setToken: action,
      removeToken: action,
      setValue: action,
    })
  }

  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }

  getToken() {
    this.token = getLocalStorageValue('token')
    return this.token
  }

  setToken(token: string) {
    setLocalStorageValue('token', token)
    this.token = token
  }

  removeToken() {
    deleteLocalStorageValue('token')
    this.token = null
  }

  async verify(data: any) {
    if (this.isLoading) {
      return
    }
    this.setValue('isLoading', true)
    const res = await apiRequest(
      '/api/v2/auth/admin/verify',
      REQ_TYPE.post,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    if (res.status === 200) {
      if (res.data.new_login) {
        this.removeToken()
      } else {
        UserStore.setValue('user', res.data.user)
        UserStore.setValue('selectLang', 'cs')
        this.setValue('isAuthorize', true)
      }
    }

    this.setValue('isLoading', false)
  }

  async login(data: any) {
    const res = await apiRequest(
      '/api/v2/auth/admin/login',
      REQ_TYPE.post,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    if (res.status === 200) {
      this.setToken(res.data.token)
      window.location.href = '/'
    }

    return res.status === 200
  }

  async getFilteredSvj(email: string) {
    const res = await apiRequest(
      `/api/v2/auth/searchByEmail/${encodeURIComponent(email)}`,
      REQ_TYPE.get,
      // @ts-ignore
      {},
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    if (res.status === 200) {
      if (res.data.svj_list && res.data.svj_list.length > 1) {
        SvjStore.setValue('svj_list', res.data.svj_list)
        SvjStore.setValue('svj_users_list', res.data.users)
        SvjStore.setValue('selectSvj', true)
      } else if (res.data.svj_list && res.data.svj_list.length === 1) {
        setLocalStorageValue('svj', res.data.svj_list[0].session_user_id)
        window.location.href = '/'
      }
    }
  }
}
export default new AuthStore()
