/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {observer} from 'mobx-react'
import UserStore from '../../../../app/Stores/User'
import {getUserIcon, logout} from '../../../../app/Helpers/User'
import {useIntl} from 'react-intl'
import {getTranslation} from '../../../../app/Helpers/Default'
const HeaderUserMenu: FC = observer(() => {
  // const dispatch = useDispatch()
  const intl = useIntl()
  const history = useHistory()

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          {getUserIcon(UserStore.user)}

          <div className='d-flex flex-column ms-5'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {UserStore.user.firstName} {UserStore.user.lastName}
            </div>
            <span className='fw-bold text-muted fs-7'>{UserStore.user.email}</span>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link
          replace={true}
          to={`/sousede/${UserStore.user.id}`}
          className='menu-link px-5'
          onClick={() => {
            history.push({
              pathname: `/sousede/${UserStore.user.id}`,
            })
            UserStore.get(UserStore.user.id)
            UserStore.setValue('loadDetail', !UserStore.loadDetail)
          }}
        >
          {getTranslation('BASE.TOP_BAR.USER.SHOW_MY_PROFILE', intl)}
        </Link>
      </div>
      <div className='separator my-2'></div>

      {/*<Languages />*/}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {getTranslation('BASE.LOGOUT', intl)}
        </a>
      </div>
    </div>
  )
})

export {HeaderUserMenu}
