import {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

export const useSvjSessionUserIdQueryParam = () => {
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    // const userSessionId = localStorage.getItem('svj')
    // const searchParams = new URLSearchParams(location.search)
    // if (userSessionId && searchParams.get('svj') !== userSessionId) {
    //   searchParams.set('svj', userSessionId)
    //   history.replace({pathname: location.pathname, search: searchParams.toString()})
    // }
  }, [location, history])
}
