import {action, makeAutoObservable} from 'mobx'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {REQ_TYPE} from '../../Constants/Request'
import moment from 'moment'
import {base64ToBlob} from '../../Helpers/Default'

class VoteStore {
  data: any = []
  gettingOwners = false
  sendingEmail: boolean = false
  finishing: boolean = false
  deleting: boolean = false
  isPrinting: boolean = false
  gettingTickets: boolean = false
  isSearching: boolean = false

  allCount: number = 0
  activeCount: number = 0
  finishedCount: number = 0
  conceptCount: number = 0
  constructor() {
    makeAutoObservable(this, {
      setValue: action,
      create: action,
      getAll: action,
      finish: action,
      update: action,
      get: action,
    })
  }
  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }

  async getVoteUsers(id: string) {
    this.setValue('gettingOwners', true)
    const res = await apiRequest(
      `/api/v2/vote/${id}/users`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('gettingOwners', false)
    return res.data
  }

  async update(newData: any, id: string) {
    const res = await apiRequest(
      `/api/v2/vote/${id}`,
      REQ_TYPE.put,
      newData,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    return res.status === 200
  }

  async create(newData: any) {
    const res = await apiRequest(
      '/api/v2/vote',
      REQ_TYPE.post,
      newData,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    return res.status === 200
  }

  async sendCode(voteId: string, ids: string) {
    this.setValue('sendingEmail', true)
    const res = await apiRequest(
      `/api/v2/vote/${voteId}/code?ids=${ids}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('sendingEmail', false)
    return res.status === 200
  }
  async getAll(params: any) {
    const res = await apiRequest(
      `/api/v2/vote/getAll?tab=${params}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    if (res && res.data && res.data.votes) {
      this.setValue('data', res.data.votes)
      this.setValue('allCount', res.data?.allCount || 0)
      this.setValue('activeCount', res.data?.activeCount || 0)
      this.setValue('finishedCount', res.data?.finishCount || 0)
      this.setValue('conceptCount', res.data?.conceptCount || 0)
    } else {
      this.setValue('data', [])
    }
  }

  base64ToBlob(base64: any, mimeType: any) {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], {type: mimeType})
  }

  async voteTickets(id: string, users: any) {
    this.setValue('gettingTickets', true)
    const res = await apiRequest(
      `/api/v2/vote/${id}/voteTickets`,
      REQ_TYPE.post,
      // @ts-ignore
      {
        users: users,
      },
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    const pdfBlob = this.base64ToBlob(res.data, 'application/pdf')
    const downloadUrl = window.URL.createObjectURL(pdfBlob)
    const a = document.createElement('a')
    a.href = downloadUrl
    const actualDate = moment().format('YYYY-MM-DD')
    a.download = 'hlasovaci-listky-' + actualDate + '.pdf'
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(downloadUrl)
    a.remove()
    this.setValue('gettingTickets', false)
    return res.status === 200
  }
  async get(id: string) {
    const res = await apiRequest(
      `/api/v2/vote/${id}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    return res.data
  }

  async storeWithCode(voteId: string, points: any, code: string, ids: string) {
    return await apiRequest(
      `/api/v2/vote/${voteId}/storeWithCode?ids=${ids}`,
      REQ_TYPE.post,
      // @ts-ignore
      {points: points, code: code},
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
  }

  async finish(voteId: string) {
    this.setValue('finishing', true)
    const res = await apiRequest(
      `/api/v2/vote/${voteId}/finish`,
      REQ_TYPE.put,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('finishing', false)
    return res.status === 200
  }

  async remove(id: string) {
    this.setValue('deleting', true)
    const res = await apiRequest(
      `/api/v2/vote/${id}`,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('deleting', false)
    return res.status === 200
  }

  async search(text: string) {
    this.setValue('isSearching', true)
    const res = await apiRequest(
      `/api/v2/vote/search/${encodeURIComponent(text)}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isSearching', false)
    return res.data ?? []
  }
}
export default new VoteStore()
