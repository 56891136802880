import { action, makeAutoObservable } from "mobx";
import { apiRequest, getHeaders } from "../../Helpers/Request";
import { REQ_TYPE } from "../../Constants/Request";
import { downloadFile } from "../../Helpers/Default";

class OrderStore {
  data = [];
  isLoading = false;
  isCreating = false;
  isLoadingEmployee = false;
  isUpdating = false;
  constructor() {
    makeAutoObservable(this, {
      setValue: action,
      getAll: action,
      get: action,
    });
  }

  setValue = (key: string, value: any) => {
    // @ts-ignore
    this[key] = value;
  };

  create = async (data: any) => {
    this.setValue("isCreating", true);
    const response = await apiRequest(
      `/api/v2/order/admin`,
      REQ_TYPE.post,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ""
    );
    this.setValue("isCreating", false);

    return response.status === 200;
  };

  getForm = async (urlId: any) => {
    this.setValue("isLoading", true);
    const response = await apiRequest(
      `/api/v2/order/admin/form/${urlId}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ""
    );
    this.setValue("isLoading", false);
    if (response.status !== 200) {
      return null;
    }
    return response.data;
  };

  getCheckForm = async (urlId: any) => {
    this.setValue("isLoading", true);
    const response = await apiRequest(
      `/api/v2/order/admin/form/${urlId}/check`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ""
    );
    this.setValue("isLoading", false);
    if (response.status !== 200) {
      return null;
    }
    return response.data;
  };

  get = async (id: any) => {
    this.setValue("isLoading", true);
    const response = await apiRequest(
      `/api/v2/order/admin/${id}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ""
    );
    this.setValue("isLoading", false);

    return response.status === 200;
  };

  getEmployee = async () => {
    this.setValue("isLoadingEmployee", true);
    const response = await apiRequest(
      `/api/v2/order/admin/employee`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ""
    );
    this.setValue("isLoadingEmployee", false);

    return response.data;
  };

  getAll = async () => {
    this.setValue("isLoading", true);
    const response = await apiRequest(
      `/api/v2/order/admin`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ""
    );
    this.setValue("isLoading", false);
    this.setValue("data", response.data);
    return response.status === 200;
  };

  newToken = async (id: string) => {
    this.setValue("isUpdating", true);
    const response = await apiRequest(
      `/api/v2/order/admin/${id}/new-token`,
      REQ_TYPE.put,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ""
    );
    this.setValue("isUpdating", false);

    return response.status === 200;
  };

  async fillOrder(data: any, id: string) {
    this.setValue("isUpdating", true);
    const response = await apiRequest(
      `/api/v2/order/admin/${id}/fill`,
      REQ_TYPE.put,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ""
    );
    this.setValue("isUpdating", false);

    return response.status === 200;
  }

  async acceptOrder(data: any, id: string) {
    this.setValue("isUpdating", true);
    const response = await apiRequest(
      `/api/v2/order/admin/${id}/accept`,
      REQ_TYPE.put,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ""
    );
    this.setValue("isUpdating", false);

    return response.status === 200;
  }

  async delete(id: string) {
    this.setValue("isUpdating", true);
    const response = await apiRequest(
      `/api/v2/order/admin/${id}`,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ""
    );
    this.setValue("isUpdating", false);

    return response.status === 200;
  }

  async getAcceptProtocol(svjId: any) {
    this.setValue("isUpdating", true);
    const res = await apiRequest(
      `/api/v2/svj/admin/accept-protocol/${svjId}`,
      REQ_TYPE.put,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ""
    );
    this.setValue("isUpdating", false);

    const binaryString = atob(res.data);

    // Vytvoření ArrayBuffer a přidání binárních dat
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Vytvoření Blob z ArrayBuffer
    const blob = new Blob([bytes], { type: "application/pdf" });

    downloadFile(URL.createObjectURL(blob), "akceptacni-protokol");
    return res.status === 200;
  }
}

export default new OrderStore();
