/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {lazy, useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import ForgotPassword from './components/ForgotPassword'
import Login from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import SvjStore from '../../Stores/Svj'
import AuthStore from '../../Stores/Auth'
import {observer} from 'mobx-react'
import {getRandomKey, getTranslation} from '../../Helpers/Default'
import {useIntl} from 'react-intl'
import _ from 'lodash'
import UserStore from '../../Stores/User'
import {toJS} from 'mobx'
const OrderPublic = lazy(() => import('../../Pages/Public/OrderPublic'))
const UserRegistrationPublicForm = lazy(() => import('../../Pages/Public/UserRegistrationPublic'))
const AuthPage = observer(() => {
  const intl = useIntl()
  const [svj_list_org, setSvjListOrg] = React.useState(_.cloneDeep(SvjStore.svj_list))
  const [svj_list, setSvjList] = React.useState(_.cloneDeep(SvjStore.svj_list))

  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  useEffect(() => {
    setSvjListOrg(_.cloneDeep(SvjStore.svj_list))
    setSvjList(_.cloneDeep(SvjStore.svj_list))
  }, [SvjStore.svj_list])

  useEffect(() => {
    // async function checkVerify() {
    //   AuthStore.getToken()
    //   console.log('bbbb')
    //   // @ts-ignore
    //   await AuthStore.verify()
    // }
    // if (SvjStore.getSVJ() && AuthStore.getToken()) {
    //   checkVerify()
    // }
  }, [])

  const handleEnterSvj = async (session_svj_id: string) => {
    SvjStore.setSVJ(session_svj_id)
    console.log('hale 2')
    // @ts-ignore
    await AuthStore.verify()
  }

  // if (AuthStore.isLoading) {
  //   return <div>'LOADING'</div>
  // }

  const renderSvjList = () => {
    const html = []

    for (let i in svj_list) {
      const svj = svj_list[i]
      html.push(
        <div className='card card-shadow border mb-5' key={getRandomKey()}>
          <div className='card-body'>
            <div className='d-sm-flex justify-content-between align-items-center w-100 '>
              <div className='w-auto mb-6 mb-sm-0'>
                <h6 className='m-0'>{svj.name}</h6>
                {svj.nickname ? (
                  <div className={'mt-2'}>
                    <span className={'text-muted fw-bold-custom fs-7 pe-4'}>
                      {getTranslation('SVJ.SETTINGS.NICKNAME', intl)}:
                    </span>
                    <span className='fw-bold-custom fs-7'>{svj.nickname}</span>
                  </div>
                ) : null}
              </div>
              <div className='d-flex justify-content-center align-items-center flex-column min-w-125px ms-0 ms-sm-6'>
                <div
                  className='btn btn-color-success bold bg-hover-light-success border-hover border-hover-success'
                  onClick={() => {
                    handleEnterSvj(svj.session_user_id)
                  }}
                >
                  <i className='bi bi-door-open'></i> {getTranslation('SVJ.LIST.ENTER', intl)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return html
  }

  if (SvjStore.selectSvj) {
    return (
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
        }}
      >
        {/* begin::Content */}
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          {/* begin::Logo */}
          <a href='#' className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.svg')} className='h-45px' />
          </a>
          <div className='mw-lg-1000px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <div>
              <h4 className='text-dark mb-8'>
                {getTranslation('SVJ.MODAL.SELECT_SVJ.TITLE', intl)}
              </h4>
            </div>
            <div className={'search w-100 mb-6'}>
              <div className='input-group mb-5'>
                <input
                  type='text'
                  className='form-control'
                  onChange={async (e) => {
                    const keyword = e.target.value
                    if (keyword === '') {
                      setSvjList(svj_list_org)
                    } else {
                      if (
                        AuthStore.searchByEmail &&
                        AuthStore.searchByEmail.indexOf('@svjaplikace.cz') > -1 &&
                        keyword.toLowerCase().indexOf('@') > -1
                      ) {
                        const foundEmails = SvjStore.svj_users_list.filter((svjUser: any) => {
                          return (
                            svjUser.user &&
                            svjUser.user.email?.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                          )
                        })

                        const svjIds = foundEmails.map((svjUser: any) => svjUser.svjId)
                        const svj_list_filtered = svj_list_org.filter((svj: any) => {
                          return svjIds.includes(svj.id)
                        })
                        setSvjList(svj_list_filtered)
                      } else {
                        const svj_list_filtered = svj_list_org.filter((svj: any) => {
                          return (
                            svj.name?.toLowerCase().includes(keyword.toLowerCase()) ||
                            svj.nickname?.toLowerCase().includes(keyword.toLowerCase())
                          )
                        })
                        setSvjList(svj_list_filtered)
                      }
                    }
                  }}
                  placeholder={getTranslation('SVJ.MODAL.SELECT_SVJ.SEARCH', intl)}
                  aria-label={getTranslation('SVJ.MODAL.SELECT_SVJ.SEARCH', intl)}
                  aria-describedby='basic-addon2'
                />
                <span className='input-group-text' id='basic-addon2'>
                  <i className={'bi bi-search fs-4'}></i>
                </span>
              </div>
            </div>
            <div className='w-100'>{renderSvjList()}</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
        }}
      >
        {/* begin::Content */}
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <Switch>
          <Route path='/objednavka/:id' component={OrderPublic} />
          <Route path='/aktivace/:id' component={UserRegistrationPublicForm} />
          <Route path='/prihlasit' component={Login} />
          <Route path='/zapomenute-heslo' component={ForgotPassword} />
          <Redirect from='/auth' exact={true} to='/prihlasit' />
          <Redirect to='/prihlasit' />
        </Switch>
        {/* end::Wrapper */}
        {/* end::Content */}
        {/* begin::Footer */}
        {/*<div className='d-flex flex-center flex-column-auto p-10'>*/}
        {/*  <div className='d-flex align-items-center fw-bold-custom fs-6'>*/}
        {/*    <a href='#' className='text-muted text-hover-primary px-2'>*/}
        {/*      About*/}
        {/*    </a>*/}

        {/*    <a href='#' className='text-muted text-hover-primary px-2'>*/}
        {/*      Contact*/}
        {/*    </a>*/}

        {/*    <a href='#' className='text-muted text-hover-primary px-2'>*/}
        {/*      Contact Us*/}
        {/*    </a>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/* end::Footer */}
      </div>
    </>
  )
})
export default AuthPage
