/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useContext, useEffect, useState} from 'react'

export interface PageLink {
  title: any
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  linkBack?: any
  setLinkBack?: (_link: any) => void
  pageTitle?: string
  setPageTitle: (_title: any) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
  pageButtons?: Array<any>
  setPageButtons: (_buttons: Array<any>) => void
  pageRightPanel?: any
  setPageRightPanel: (component: any) => void
  showPageRightPanel?: boolean
  setShowPageRightPanel: (_show: boolean) => void
  helpLink?: string
  setHelpLink: (_link: string) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setLinkBack: (_link: any) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
  setPageButtons: (_buttons: Array<any>) => {},
  setPageRightPanel: (_component: any) => {},
  setShowPageRightPanel: (_show: boolean) => {},
  setHelpLink: (_link: string) => {},
})

const PageDataProvider: React.FC = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [linkBack, setLinkBack] = useState<any>('')
  const [helpLink, setHelpLink] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const [pageButtons, setPageButtons] = useState<Array<any>>([])
  const [pageRightPanel, setPageRightPanel] = useState<any>()
  const [showPageRightPanel, setShowPageRightPanel] = useState<boolean>(false)
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    linkBack,
    setLinkBack,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    pageButtons,
    setPageButtons,
    pageRightPanel,
    setPageRightPanel,
    showPageRightPanel,
    setShowPageRightPanel,
    helpLink,
    setHelpLink,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
  pageButtons?: Array<any>
  show?: boolean
  linkBack?: any
  helpLink?: string
}

const PageTitle: FC<Props> = ({
  children,
  description,
  breadcrumbs,
  pageButtons,
  linkBack,
  helpLink,
}) => {
  const {
    setPageTitle,
    setPageDescription,
    setPageBreadcrumbs,
    setPageButtons,
    setLinkBack,
    setHelpLink,
  } = usePageData()
  useEffect(() => {
    if (children) {
      setPageTitle(children)
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (linkBack) {
      // @ts-ignore
      setLinkBack(linkBack || '')
    }
    return () => {
      // @ts-ignore
      setLinkBack('')
    }
  }, [linkBack])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  useEffect(() => {
    if (pageButtons) {
      setPageButtons(pageButtons)
    }
    return () => {
      setPageButtons([])
    }
  }, [pageButtons])

  useEffect(() => {
    if (helpLink) {
      setHelpLink(helpLink)
    }
    return () => {
      setHelpLink('')
    }
  }, [helpLink])

  return <></>
}

const PageDescription: React.FC = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

const PageRightPanel: React.FC<Props> = ({children, show}) => {
  const {setPageRightPanel, setShowPageRightPanel} = usePageData()
  useEffect(() => {
    if (children) {
      setPageRightPanel(children)
    }

    if (show) {
      setShowPageRightPanel(true)
    } else {
      setShowPageRightPanel(false)
    }

    return () => {
      setPageRightPanel('')
      setShowPageRightPanel(false)
    }
  }, [children, show])
  return <></>
}

const ShowPageRightPanel: React.FC<Props> = ({show}) => {
  // const {setShowPageRightPanel} = usePageData()
  // useEffect(() => {
  //   if (show) {
  //     setShowPageRightPanel(show)
  //   }
  //   return () => {
  //     setShowPageRightPanel(false)
  //   }
  // }, [show])
  return <></>
}

export {
  PageDescription,
  PageTitle,
  ShowPageRightPanel,
  PageRightPanel,
  PageDataProvider,
  usePageData,
}
