import {action, makeAutoObservable} from 'mobx'
import {REQ_TYPE} from '../../Constants/Request'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {showNotification} from '../../Helpers/Notification'
import {createFormData, hasModule} from '../../Helpers/Default'
import {MEETING_OWNERS_MODULE, REPORTS_MODULE} from '../../Constants/Modules'

class ReportsStore {
  data = []
  isCreating = false
  isLoading = false
  isUpdating = false
  isPrinting = false
  isSearching = false
  allCount: number = 0
  newCount: number = 0
  inProgressCount: number = 0
  doneCount: number = 0
  archivedCount: number = 0

  constructor() {
    makeAutoObservable(this, {
      setValue: action,
      create: action,
      getAll: action,
      get: action,
    })
  }

  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }

  async create(data: any, attachments: any[] = []) {
    const res = await apiRequest(
      '/api/v2/report',
      REQ_TYPE.post,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    for (const file of attachments) {
      const formData = createFormData(file)

      const res2 = await apiRequest(
        '/api/v2/report/upload/' + res.data.id,
        REQ_TYPE.post,
        // @ts-ignore
        formData,
        // @ts-ignore
        getHeaders(null, {'content-type': 'multipart/form-data'}),
        this,
        ''
      )
    }
    return res.status === 200
  }

  async getAll(tab: string) {
    const res = await apiRequest(
      `/api/v2/report${tab ? `?tab=${tab}` : ''}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    if (res && res.data) {
      this.setValue('allCount', res.data.reportsCount)
      this.setValue('newCount', res.data.newReportsCount)
      this.setValue('inProgressCount', res.data.solvingReportsCount)
      this.setValue('doneCount', res.data.solvedReportsCount)
      this.setValue('archivedCount', res.data.archiveReportsCount)
      this.setValue('data', res.data.reports)
    }
  }

  async get(id: string) {
    const res = await apiRequest(
      `/api/v2/report/${id}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    if (res && res.status === 200) {
      return res.data
    }
    return null
  }

  async update(data: any, attachments: any[] = []) {
    this.setValue('isUpdating', true)
    const res = await apiRequest(
      `/api/v2/report/${data.id}`,
      REQ_TYPE.put,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    for (const file of attachments) {
      const formData = createFormData(file)

      const res2 = await apiRequest(
        '/api/v2/report/upload/' + data.id,
        REQ_TYPE.post,
        // @ts-ignore
        formData,
        // @ts-ignore
        getHeaders(null, {'content-type': 'multipart/form-data'}),
        this,
        ''
      )
    }
    this.setValue('isUpdating', false)
    return res.status === 200
  }

  async addComment(id: any, comment: any) {
    const res = await apiRequest(
      `/api/v2/report/${id}/comment`,
      REQ_TYPE.post,
      // @ts-ignore
      {comment: comment},
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    // console.log(res)
    // showNotification('success', 'REPORT.FORM.NOTIFICATION.SUCCESS')

    return res.status === 200
  }
  async getComments(id: any) {
    const res = await apiRequest(
      `/api/v2/report/${id}/comment`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    if (res && res.status === 200) {
      return res.data
    }
    return null
  }

  async archiveReport(id: any) {
    const res = await apiRequest(
      `/api/v2/report/${id}/archive`,
      REQ_TYPE.put,
      // @ts-ignore
      {},
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    return res && res.status === 200
  }

  async delete(id: any) {
    const res = await apiRequest(
      `/api/v2/report/${id}`,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    return res && res.status === 200
  }

  async search(text: string) {
    if (!hasModule(REPORTS_MODULE)) {
      return []
    }
    this.setValue('isSearching', true)
    const res = await apiRequest(
      `/api/v2/report/search/${encodeURIComponent(text)}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isSearching', false)
    return res.data ?? []
  }
}
export default new ReportsStore()
