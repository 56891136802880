import http from './../Http'
import AuthStore from '../../Stores/Auth'
// import { showNotification } from '../notification'
import React, {useEffect} from 'react'
import {NOTIFICATION_TYPE} from '../../Constants/Notification'
import {deleteLocalStorageValue, getLocalStorageValue} from '../LocalStorage'
import {showBulkNotification, showNotification} from '../Notification'
import SvjStore from './../../Stores/Svj'
import {useIntl} from 'react-intl'

export const getHeaders = (
  token = '',
  addition = undefined,
  config = undefined,
  svj: undefined | any = undefined
) => {
  if (!token) {
    token = AuthStore.token
  }
  if (!svj) {
    svj = SvjStore.getSVJ()
  }

  let headers = {
    authorization: 'Bearer ' + token,
    svj: svj,
    // "Access-Control-Allow-Credentials": "true"
  }

  if (addition) {
    // @ts-ignore
    for (let i in addition) {
      // @ts-ignore
      headers[i] = addition[i]
    }
  }

  let request = {
    headers: headers,
  }

  if (config) {
    // @ts-ignore
    for (let i in config) {
      // @ts-ignore
      request[i] = config[i]
    }
  }

  return request
}

// export function getAuthHeaderToken() {
//     return 'Bearer '+getLocalStorageValue('token');
// }

export async function apiRequest(
  url: string,
  type: string,
  data = null,
  settings = undefined,
  parent = null,
  lock_name = null
) {
  let req = {
    status: null,
    data: null,
  }

  try {
    // url = process.env.REACT_APP_BACKEND_URL + '/api/v1/' + url;
    switch (type) {
      case 'get':
        req = await http.get(url, settings)
        break
      case 'put':
        req = await http.put(url, data, settings)
        break
      case 'post':
        req = await http.post(url, data, settings)
        break
      case 'delete':
        req = await http.delete(url, settings)
        break
      default:
        showNotification(
          NOTIFICATION_TYPE.warning,
          'Bohužel se něco nepodařilo. Prosím opakujte akci později znovu.'
        )
        break
    }

    if (req && req.data) {
      if (req.data['message'] && req.data['message']['show']) {
        showNotification(
          req.data['code'] === 200 ? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger,
          req.data['message']['content']
        )
      }
      // @ts-ignore
      return {
        status: req.data['code'],
        data: req.data['data'] ? req.data['data'] : req.data,
        message: req.data['message'] ? req.data['message']['content'] : '',
      }
    }
    return {
      status: 500,
      data: {},
      message: '',
    }
  } catch (e: any) {
    console.log('Error requests', e)
    if (parent && lock_name) {
      // @ts-ignore
      parent.setValue('lock_name', false)
    }

    if (e.response.data && e.response.data.message) {
      if (e.response.data.message.show) {
        showNotification(
          e.response.data.code === 200 ? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger,
          e.response.data.message.content
        )
      }
    }
    // @ts-ignore
    return {
      status: e.response.status,
      data: e.response.data ? e.response.data.data : {},
      message: e.response.data ? e.response.data.message.content : {},
    }
  }
}

export function redirectByLocalStorage() {
  const redirect_value = getLocalStorageValue('redirect')
  if (redirect_value) {
    if (redirect_value.indexOf('login') === -1) {
      // history.push(getLocalStorageValue('redirect'))
    }
    deleteLocalStorageValue('redirect')
    return true
  }
  return false
}
