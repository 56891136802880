import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { FallbackView } from "../../_metronic/partials";
import { DashboardWrapper } from "../Pages/Dashboard/DashboardWrapper";
import { MenuTestPage } from "../Pages/MenuTestPage";
import { getRandomKey } from "../Helpers/Default";
import VoteDetail from "../Pages/Vote/Detail";
import { useSvjSessionUserIdQueryParam } from "../Hooks/SvjUrl";
import SurveyResult from "../Pages/Survey/Result";
import OrderAdmin from "../Pages/Admin/Order/intex";
import AdminOrderForm from "../Pages/Admin/Order/form";
import AdminOrderCheck from "../Pages/Admin/Order/check";

export function PrivateRoutes() {
  useSvjSessionUserIdQueryParam();
  const Error404 = lazy(() => import("../modules/errors/components/Error404"));
  //admin
  const Invoices = lazy(() => import("../Pages/Invoices"));
  const Svjs = lazy(() => import("../Pages/Admin/Svjs"));
  const Svj = lazy(() => import("../Pages/Svj"));

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route
          key={getRandomKey()}
          path={`/nastenka`}
          component={DashboardWrapper}
        />
        <Route key={getRandomKey()} path={`/faktury`} component={Invoices} />
        <Route key={getRandomKey()} path={`/svjs/:id`} component={Svj} />
        <Route key={getRandomKey()} path={`/svjs`} component={Svjs} />
        <Route
          key={getRandomKey()}
          path={`/objednavky`}
          exact={true}
          component={OrderAdmin}
        />
        <Route
          key={getRandomKey()}
          path={`/objednavky/novy`}
          exact={true}
          component={AdminOrderForm}
        />
        <Route
          key={getRandomKey()}
          path={`/objednavky/:id/kontrola`}
          exact={true}
          component={AdminOrderCheck}
        />

        <Route
          key={getRandomKey()}
          path={`/objednavky/:id`}
          exact={true}
          component={AdminOrderForm}
        />

        <Route
          key={getRandomKey()}
          path={"/nastenka"}
          component={DashboardWrapper}
        />
        <Route key={getRandomKey()} path="/404" component={Error404} />
        {/*<Redirect from='/auth' to='/dashboard' />*/}
        <Redirect key={getRandomKey()} from="/" to="/nastenka" />
      </Switch>
    </Suspense>
  );
}
