/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { getRandomKey, getTranslation } from "../../../../app/Helpers/Default";
import { observer } from "mobx-react";
import Invoices from "../../../../app/Pages/Invoices";

export const AsideMenuMain = observer(() => {
  const intl = useIntl();

  return (
    <>
      <AsideMenuItem
        to="/nastenka"
        icon="/media/icons/duotune/maps/map009.svg"
        title={getTranslation("MENU.DASHBOARD", intl)}
        bold={true}
        // fontIcon='fa fa-map-signs'
        // icon='/media/icons/duotune/maps/map009.svg'
      />
      {/*<div className='menu-item'>*/}
      {/*  <div className='menu-content pt-8 pb-2'>*/}
      {/*    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <AsideMenuItemWithSub
        to="/crafted/pages"
        title="Menu"
        bold={true}
        active={true}
        icon={"/media/icons/duotune/general/gen033.svg"}
      >
        <AsideMenuItem
          to={`/objednavky`}
          title={getTranslation("ADMIN.MENU.ORDERS", intl)}
          icon="/media/icons/duotune/general/gen014.svg"
          key={getRandomKey()}
          visible={true}
        />

        <AsideMenuItem
          to={`/faktury`}
          title={getTranslation("ADMIN.MENU.INVOICE", intl)}
          icon="/media/icons/duotune/general/gen014.svg"
          key={getRandomKey()}
          visible={true}
        />

        <AsideMenuItem
          to={`/svjs`}
          title={getTranslation("ADMIN.MENU.SVJS", intl)}
          icon="/media/icons/duotune/general/gen014.svg"
          key={getRandomKey()}
          visible={true}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to="/crafted/pages"
        title="Podpora"
        bold={true}
        // fontIcon='bi-archive'
        // icon='/media/icons/duotune/general/gen022.svg'
        icon={"/media/icons/duotune/general/gen046.svg"}
      >
        <AsideMenuItem
          to="https://helpdesk.svjaplikace.cz/"
          external={true}
          title={getTranslation("MENU.FAQ", intl)}
          icon="/media/icons/duotune/abstract/abs035.svg"
        />
        <AsideMenuItem
          external={true}
          to="https://helpdesk.svjaplikace.cz/zpetna-vazba/"
          title={getTranslation("MENU.FEEDBACK", intl)}
          icon="/media/icons/duotune/abstract/abs026.svg"
        />
        <AsideMenuItem
          external={true}
          to=" https://helpdesk.svjaplikace.cz/aktualizace-aplikace/"
          title={getTranslation("MENU.UPDATE_INFO", intl)}
          icon="/media/icons/duotune/arrows/arr029.svg"
        />
      </AsideMenuItemWithSub>
    </>
  );
});
