import {action, makeAutoObservable} from 'mobx'
import {REQ_TYPE} from '../../Constants/Request'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {base64ToBlob, createFormData, hasModule} from '../../Helpers/Default'
import {MEETING_OWNERS_MODULE, SURVEY_MODULE} from '../../Constants/Modules'
import moment from 'moment/moment'

class SurveysStore {
  data = []
  isLoading = false
  isSaving = false
  isCreating: boolean = false
  isDeleting: boolean = false
  isPrinting: boolean = false
  isSearching: boolean = false
  gettingTickets: boolean = false
  allCount: number = 0
  activeCount: number = 0
  conceptCount: number = 0
  finishedCount: number = 0

  constructor() {
    makeAutoObservable(this, {
      setValue: action,
      create: action,
      getAll: action,
      get: action,
    })
  }

  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }

  async create(data: any, attachments: any) {
    this.setValue('isSaving', true)
    const res = await apiRequest(
      '/api/v2/survey',
      REQ_TYPE.post,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    for (const file of attachments) {
      const formData = createFormData(file)

      const res2 = await apiRequest(
        `/api/v2/survey/upload/${res.data.id}`,
        REQ_TYPE.post,
        // @ts-ignore
        formData,
        // @ts-ignore
        getHeaders(null, {'content-type': 'multipart/form-data'}),
        this,
        ''
      )
    }
    this.setValue('isSaving', false)
    return res.status === 200
  }

  async getAll(type: any) {
    this.setValue('isLoading', true)
    const res = await apiRequest(
      `/api/v2/survey?tab=${type}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    if (res && res.data) {
      this.setValue('data', res.data.surveys)
      this.setValue('allCount', res.data.surveyCount)
      this.setValue('activeCount', res.data.activeCount)
      this.setValue('conceptCount', res.data.conceptCount)
      this.setValue('finishedCount', res.data.finishedCount)
    }
    this.setValue('isLoading', false)
  }

  async get(id: string) {
    this.setValue('isLoading', true)
    let res = await apiRequest(
      `/api/v2/survey/${id}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isLoading', false)
    return res.data
  }

  async storeVote(id: string, data: any) {
    this.setValue('isSaving', true)
    const res = await apiRequest(
      `/api/v2/survey/${id}/vote/store`,
      REQ_TYPE.post,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isSaving', false)
    return res.status === 200
  }

  async remove(id: string) {
    this.setValue('isDeleting', true)
    const res = await apiRequest(
      `/api/v2/survey/${id}`,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isDeleting', false)
    return res.status === 200
  }

  async deleteAnwer(id: string) {
    this.setValue('isDeleting', true)
    const res = await apiRequest(
      `/api/v2/survey/answer/${id}`,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isDeleting', false)
    return res.status === 200
  }
  async update(id: string, data: any, attachments: any) {
    this.setValue('isSaving', true)
    const res = await apiRequest(
      `/api/v2/survey/${id}`,
      REQ_TYPE.put,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    for (const file of attachments) {
      const formData = createFormData(file)

      const res2 = await apiRequest(
        `/api/v2/survey/upload/${id}`,
        REQ_TYPE.post,
        // @ts-ignore
        formData,
        // @ts-ignore
        getHeaders(null, {'content-type': 'multipart/form-data'}),
        this,
        ''
      )
    }
    this.setValue('isSaving', false)
    return res.status === 200
  }

  async finish(id: string) {
    this.setValue('isSaving', true)
    const res = await apiRequest(
      `/api/v2/survey/${id}/finish`,
      REQ_TYPE.put,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isSaving', false)
    return res.status === 200
  }

  async search(text: string) {
    if (!hasModule(SURVEY_MODULE)) {
      return []
    }
    this.setValue('isSearching', true)
    const res = await apiRequest(
      `/api/v2/survey/search/${encodeURIComponent(text)}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isSearching', false)
    return res.data ?? []
  }

  base64ToBlob(base64: any, mimeType: any) {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], {type: mimeType})
  }

  async voteTickets(id: string, users: any) {
    this.setValue('gettingTickets', true)
    const res = await apiRequest(
      `/api/v2/survey/${id}/voteTickets`,
      REQ_TYPE.post,
      // @ts-ignore
      {
        users: users,
      },
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    const pdfBlob = this.base64ToBlob(res.data, 'application/pdf')
    const downloadUrl = window.URL.createObjectURL(pdfBlob)
    const a = document.createElement('a')
    a.href = downloadUrl
    const actualDate = moment().format('YYYY-MM-DD')
    a.download = 'hlasovaci-listky-' + actualDate + '.pdf'
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(downloadUrl)
    a.remove()
    this.setValue('gettingTickets', false)
    return res.status === 200
  }
}
export default new SurveysStore()
